import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import "./stylesheets/_custom.scss";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "./components/middleware/AxiosRequests";
import * as serviceWorker from "./serviceWorker";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");
toast.configure();

ReactGA.initialize("UA-159381031-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
