import React from "react";
import styles from "./Home.module.scss";
import jwt from "jsonwebtoken";
import moment from "moment";

const Home = props => {
  props.hideNavbar(false);
  const [username, setUsername] = React.useState("");
  const [date, setDate] = React.useState("");
  const [clock, setClock] = React.useState("");

  const GetUsername = () => {
    try {
      const token = jwt.decode(localStorage.getItem("refresh-token"));
      setUsername(token.user.naam.toString());
    } catch {
      return null;
    }
  };
  const GetDate = () => {
    const _date = moment().format("DD MMMM YYYY");
    setDate(_date);
  };

  const GetClock = () => {
    const _clock = moment().format("HH:mm:ss");
    setClock(_clock);
  };

  if (username === "") GetUsername();
  if (date === "") GetDate();
  if (clock === "") GetClock();

  setInterval(() => {
    GetClock();
  }, 1000);

  return (
    <div className={styles.HomeWrapper}>
      <div className={[styles.Clock, "display-1"].join(" ")}>{clock}</div>
      <h1 className={[styles.Title, "my-4"].join(" ")}>Welkom, {username}</h1>
      <h4>Het is vandaag {date}</h4>
    </div>
  );
};

export default Home;
