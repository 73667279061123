import React, { Component } from "react";
import {
  Col,
  Container,
  InputGroup,
  Table,
  Spinner,
  Row,
  FormControl,
  Button,
  Modal,
  Form,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as editIcon,
  faTrash as deleteIcon,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../../components/auth/PermissionValidation";

const API = process.env.REACT_APP_AUTH_API_URI;
export default class BeheerMerken extends Component {
  state = {
    data: { merken: [] },
    isFetching: false,
    showDeleteModal: false,
    showAddModal: false,
    showEditModal: false,
    isDeleting: false,
    isAdding: false,
    isEditing: false,
    merkToDelete: 0,
    merkToEdit: 0,
    merknaam: "",
    currentMerknaam: "",
    filter: ""
  };

  GetMerkData = async () => {
    this.setState({ isFetching: true });
    await Promise.all([Axios.get(API + "/merken")]).then(res => {
      this.setState({
        data: {
          merken: res[0].data
        },
        isFetching: false
      });
    });
  };

  DeleteMerkData = async () => {
    this.setState({ isDeleting: true });
    await Axios.delete(API + "/merken/" + this.state.merkToDelete)
      .then(res => {
        this.GetMerkData();
      })
      .catch(err => {
        this.GetMerkData();
      });
    this.setState({ showDeleteModal: false, isDeleting: false, merkToDelete: 0 });
  };

  AddMerkData = async event => {
    event.preventDefault();
    this.setState({ isAdding: true });
    await Axios.post(API + "/merken", {
      merknaam: this.state.merknaam
    })
      .then(res => this.GetMerkData())
      .catch(this.GetMerkData());
    this.setState({ showAddModal: false, isAdding: false, merknaam: "" });
  };

  EditMerkData = async event => {
    event.preventDefault();
    this.setState({ isEditing: true });
    await Axios.put(API + "/merken/" + this.state.merkToEdit, {
      merknaam: this.state.merknaam
    })
      .then(res => this.GetMerkData())
      .catch(this.GetMerkData());
    this.setState({ showEditModal: false, isEditing: false, merknaam: "", currentMerknaam: "" });
  };

  componentDidMount() {
    this.GetMerkData();
  }

  render() {
    return (
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Merken</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right" md={12} lg={10} xl={8}>
                <Button
                  className="float-right"
                  variant="outline-light"
                  onClick={() => this.setState({ showAddModal: true })}
                  block
                >
                  Nieuw merk toevoegen
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Merken zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => this.setState({ filter: e.target.value })}
            />
          </InputGroup>
          <Col className="p-0">
            {this.state.isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>Merknaam</th>
                    {PermissionValidation("kanWijzigen" || "kanVerwijderen", <th>Acties</th>)}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.merken
                    .filter(m => {
                      m.filterThrough = false;
                      if (this.state.filter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in m) {
                          if (m[index] !== null && m.filterThrough === false) {
                            m.filterThrough = m[index]
                              .toString()
                              .toLowerCase()
                              .includes(this.state.filter.toString().toLowerCase());
                          }
                        }
                        return m.filterThrough;
                      } else return true;
                    })
                    .map(merk => {
                      return (
                        <tr key={merk.ID}>
                          <td>{merk.Merknaam}</td>
                          {PermissionValidation(
                            "kanWijzigen" || "kanVerwijderen",
                            <td>
                              {PermissionValidation(
                                "kanWijzigen",
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() =>
                                    this.setState({
                                      merkToEdit: merk.ID,
                                      showEditModal: true,
                                      currentMerknaam: merk.Merknaam,
                                      merknaam: merk.Merknaam
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={editIcon} />
                                </Button>
                              )}
                              &nbsp;
                              {PermissionValidation(
                                "kanVerwijderen",
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({ merkToDelete: merk.ID, showDeleteModal: true });
                                  }}
                                >
                                  <FontAwesomeIcon icon={deleteIcon} />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
        {/* DELETE MERK */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-delete"
          centered
          onHide={() => this.setState({ showDeleteModal: false })}
          show={this.state.showDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-delete">Merk verwijderen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Weet u zeker dat u dit merk wilt verwijderen? Deze actie kan niet ongedaan gemaakt
              worden.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showDeleteModal: false })} variant="dark">
              Annuleren
            </Button>
            <Button onClick={this.DeleteMerkData} variant="danger">
              Verwijderen
              {this.state.isDeleting ? (
                <Spinner variant="white" animation="border" size="sm" className="ml-1" />
              ) : null}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* EDIT MERK */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showEditModal: false })}
          show={this.state.showEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Merk bewerken</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.EditMerkData}>
            <Modal.Body>
              <FormGroup>
                <FormLabel>Merknaam</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Vul hier de naam van het merk in.."
                  required
                  onChange={e => this.setState({ merknaam: e.target.value })}
                  defaultValue={this.state.currentMerknaam}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showEditModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Bewerken
                {this.state.isEditing ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* ADD MERK */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showAddModal: false })}
          show={this.state.showAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Merk toevoegen</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.AddMerkData}>
            <Modal.Body>
              <FormGroup>
                <FormLabel>Merknaam</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Vul hier de naam van het merk in.."
                  required
                  onChange={e => this.setState({ merknaam: e.target.value })}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showAddModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Toevoegen
                {this.state.isAdding ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    );
  }
}
