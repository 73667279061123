import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner,
  FormText
} from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";

const API = process.env.REACT_APP_AUTH_API_URI;
class GebruikerToevoegen extends Component {
  state = {
    rechtenData: [],
    leavePage: false,
    isFetching: false,
    gebruikerRechten: [],
    voornaam: "",
    tussenvoegsel: "",
    achternaam: "",
    email: "",
    emailConfirm: ""
  };

  fetchData = async () => {
    this.setState({ isFetching: true });
    await Axios.get(API + "/rechten")
      .then(res => this.setState({ rechtenData: res.data }))
      .catch(err => console.error(err));
    this.setState({ isFetching: false });
  };

  componentDidMount() {
    this.fetchData();
  }

  HandleSubmit = async e => {
    if (e) e.preventDefault();
    if (this.state.email !== this.state.emailConfirm)
      return toast.warn("De e-mail adressen zijn niet gelijk.");
    const { voornaam, tussenvoegsel, achternaam, email, gebruikerRechten:rechten } = this.state;
    await Axios.post(API + "/auth/register", {
      voornaam,
      tussenvoegsel,
      achternaam,
      email,
      rechten
    })
      .then(res => {
        toast.success(res.data.message);
        this.setState({ leavePage: true });
      })
      .catch(err =>
        toast.error(
          "Er is iets misgegaan tijdens het toevoegen van een gebruiker, weet u zeker dat alle gegevens correct ingevuld zijn?"
        )
      );
  };

  HandleRechten = (rechtId, checked) => {
    let _rechten = [...this.state.gebruikerRechten];
    if (rechtId === 1) {
      _rechten = [];
      _rechten.push(1);
    }
    if (checked) {
      const result = _rechten.findIndex(r => {
        return r === rechtId;
      });
      if (result < 0) _rechten.push(rechtId);
    } else {
      _rechten = _rechten.filter(r => {
        return r !== rechtId;
      });
    }
    this.setState({ gebruikerRechten: _rechten });
  };

  render() {
    return (
      <Container className="p-5">
        {this.state.leavePage ? <Redirect to="/gebruikers" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Gebruiker toevoegen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ leavePage: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              <Form onSubmit={this.HandleSubmit}>
                <Form.Row>
                  <Col md={4}>
                    <FormLabel>Voornaam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de voornaam van de gebruiker in"
                      required
                      onChange={e => this.setState({ voornaam: e.target.value })}
                    />
                  </Col>
                  <Col md={3}>
                    <FormLabel>Tussenvoegsel</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier het tussenvoegsel van de gebruiker in.."
                      onChange={e => this.setState({ tussenvoegsel: e.target.value })}
                    />
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <FormLabel>Achternaam</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier de achternaam van de gebruiker in"
                        required
                        onChange={e => this.setState({ achternaam: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>E-mail adres</FormLabel>
                      <FormControl
                        type="email"
                        placeholder="Vul hier het e-mail adres van de gebruiker in"
                        required
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>E-mail adres (bevestigen)</FormLabel>
                      <FormControl
                        type="email"
                        placeholder="Bevestig hier het e-mail adres van de gebruiker"
                        required
                        onChange={e => this.setState({ emailConfirm: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <hr />
                <Col md={12}>
                  <FormGroup>
                    <FormLabel>Gebruiker rechten</FormLabel>
                    {this.state.isFetching ? (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      this.state.rechtenData.map(recht => {
                        return (
                          <div className="mb-3" key={recht.ID}>
                            <Form.Check
                              custom
                              type="checkbox"
                              id={`custom-${recht.Alias}`}
                              label={recht.Alias}
                              onChange={e => this.HandleRechten(recht.ID, e.target.checked)}
                              checked={this.state.gebruikerRechten.includes(recht.ID)}
                              disabled={this.state.gebruikerRechten.includes(1) && recht.ID !== 1}
                            />
                            <FormText>{recht.Omschrijving}</FormText>
                          </div>
                        );
                      })
                    )}
                  </FormGroup>
                </Col>
                <hr />
                <Button block type="submit" variant="secondary">
                  Uitnodiging versturen
                </Button>
              </Form>
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default GebruikerToevoegen;
