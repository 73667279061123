import React from "react";
import { Col, FormControl, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus as AddLeverancierIcon,
  faTrash as RemoveLeverancierIcon
} from "@fortawesome/free-solid-svg-icons";

const MAX_LEVERANCIERS = process.env.REACT_APP_MAX_LEVERANCIERS || 1;
export default ({ data, leveranciers, setLeveranciers }) => {
  const HandleAddLeverancier = () => {
    if (leveranciers.length >= MAX_LEVERANCIERS) return;
    const obj = {
      objId: Math.random()
        .toString(36)
        .substr(2, 9),
      leverancierId: data[0].ID,
    };
    let _list = [...leveranciers];
    _list.push(obj);
    setLeveranciers(_list);
  };

  const HandleSelectLeverancier = (id, e) => {
    const leverancier = [...data][e.target.selectedIndex];
    let _list = [...leveranciers];
    let _obj = _list.find(item => item.objId === id);
    const index = _list.findIndex(item => item.objId === id);
    _obj.leverancierId = leverancier.ID;
    _obj.leverancierNaam = leverancier.Leverancier;
    _list[index] = _obj;
    setLeveranciers(_list);
  };



  const HandleRemoveLeverancier = () => {
    let _list = [...leveranciers];
    _list.pop();
    setLeveranciers(_list);
  };

  return (
    <Col xs={12}>
      <hr />
      <Row className="mb-3">
        <Col xs={8} md={10} xl={11} className="d-flex justify-content-start align-items-center">
          <p className="font-weight-bold m-0">
            Leveranciers beheren ({leveranciers.length}/{MAX_LEVERANCIERS})
          </p>
        </Col>
        <Col xs={4} md={2} xl={1}>
          <Button
            block
            variant="outline-success"
            onClick={HandleAddLeverancier}
            disabled={leveranciers.length >= MAX_LEVERANCIERS}
          >
            <FontAwesomeIcon icon={AddLeverancierIcon} />
          </Button>
        </Col>
      </Row>
      {leveranciers.map((leverancier, index) => {
        const last = index + 1 === leveranciers.length;
        return (
          <Row key={leverancier.objId}>
            <Col xs={12} md={11}>
              <FormControl
                as="select"
                onChange={e => HandleSelectLeverancier(leverancier.objId, e)}
                value={leverancier.leverancierNaam}
              >
                {data.map(({ Leverancier }) => {
                  return <option key={Leverancier}>{Leverancier}</option>;
                })}
              </FormControl>
            </Col>
            {last ? (
              <Col xs={4} md={2} xl={1}>
                <Button variant="outline-danger" block onClick={HandleRemoveLeverancier}>
                  <FontAwesomeIcon icon={RemoveLeverancierIcon} />
                </Button>
              </Col>
            ) : null}
          </Row>
        );
      })}
    </Col>
  );
};
