import React, { useState } from "react";
import { withRouter, Redirect } from "react-router";
import { Col, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./Login.module.scss";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ResetPassModal from "./ResetPassModal";

const Login = props => {
  props.hideNavbar(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const HandleSubmit = event => {
    if (event) event.preventDefault();

    const _user = {
      email: email,
      password: password
    };

    Axios.post(process.env.REACT_APP_AUTH_API_URI + "/auth/login", _user, {
      timeout: 3000
    })
      .then(response => {
        if (response.status === 200) {
          const _token = response.headers["x-access-token"];
          const _refreshToken = response.headers["x-refresh-token"];
          if (_token) localStorage.setItem("access-token", _token);
          if (_refreshToken) localStorage.setItem("refresh-token", _refreshToken);
          toast.success("U bent nu ingelogd");
          setSuccessful(true);
        }
      })
      .catch(error => {
        try {
          if (error.response.data.message) toast.error(error.response.data.message);
        } catch {
          return null;
        }
      });
  };

  const TogglePasswordModal = () => setShowForgotPasswordModal(!showForgotPasswordModal);

  const ToggleResetPassModal = () => setShowResetPassModal(!showResetPassModal);

  return successful ? (
    <Redirect to="/" />
  ) : (
    <div className={styles.loginWrapper}>
      <ForgotPasswordModal
        shown={showForgotPasswordModal}
        toggle={TogglePasswordModal}
        setResetEmail={setResetEmail}
        showNext={ToggleResetPassModal}
      />
      <ResetPassModal shown={showResetPassModal} toggle={ToggleResetPassModal} email={resetEmail} />
      <div className={styles.loginBackground} />
      <Col xs={11} sm={8} md={6} lg={4} className="bg-light rounded px-0 shadow">
        <div className={styles.HanLogo} />
        <Form className="p-4" onSubmit={HandleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>E-mail adres</Form.Label>
            <Form.Control
              type="email"
              placeholder="Vul hier uw e-mail adres in"
              onChange={e => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Wachtwoord</Form.Label>
            <Form.Control
              type="password"
              placeholder="Vul hier uw wachtwoord in"
              onChange={e => setPassword(e.target.value)}
              minLength={6}
              required
            />
          </Form.Group>
          <p className="text-muted text-right" onClick={TogglePasswordModal}>
            <FontAwesomeIcon icon={faInfoCircle} /> <i>Wachtwoord vergeten?</i>
          </p>
          <Button variant="primary" type="submit" block>
            Inloggen
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default withRouter(Login);
