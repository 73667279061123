import React, { Component } from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ConvertButton = ({ data }) => {
  return (
    <Button block variant="dark" disabled={data.length <= 0}>
      Overzicht
    </Button>
  );
};

export default class ArtikelenExcelExportExcel extends Component {
  state = {
    convertedData: []
  };

  columns = [
    "Artikelnummer",
    "Groep",
    "Locaties",
    "Activiteiten",
    "Omschrijving",
    "Merk",
    "Magazijn",
    "Aantal",
    "Aantal ingenomen",
    "Aantal uitgeleend",
    "Aantal defect",
    "Eigenaar"
  ];

  convertDataToExcel = data => {
    let cData = [...data];
    let cColumns = [];
    cData = cData.map(c => {
      delete c.ID;
      delete c.Afkorting;
      delete c.Plaats;
      delete c.Straat;
      delete c.Huisnummer;
      delete c.Datuminname;
      delete c.DatumReparatie;
      delete c.filterThrough;
      c.AantalIngenomen = c.AantalIngenomen > 0 ? c.AantalIngenomen : 0;
      c.AantalUitgeleend = c.AantalUitgeleend > 0 ? c.AantalUitgeleend : 0;
      c.AantalDefect = c.AantalDefect > 0 ? c.AantalDefect : 0;
      return c;
    });
    let rows = [];
    cData.forEach(cd => {
      let item = [];
      // eslint-disable-next-line
      for (let key of Object.keys(cd))
        item.push({
          value: cd[key] === undefined || cd[key] === null ? "" : cd[key].toString()
        });
      rows.push(item);
    });
    cData = [...rows];

    this.columns.forEach(c => {
      let item = {};
      item.value = c;
      item.style = {
        alignment: { horizontal: "center" },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" }
        },
        font: { bold: true, color: { rgb: "FFFFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "FFE5005B" } }
      };
      cColumns.push(item);
    });
    cData.unshift(cColumns);
    const cTopColumns = this.columns.map(ctc => {
      return { title: "", width: { wch: ctc === "Eigenaar" ? 40 : 20 } };
    });

    return [{ columns: cTopColumns, data: cData }];
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.length > 0 && this.state.convertedData.length <= 0) {
      this.setState({ convertedData: this.convertDataToExcel(this.props.data) });
    }
  }

  componentDidMount() {
    this.setState({ convertedData: this.convertDataToExcel(this.props.data) });
  }

  render() {
    return this.state.convertedData.length > 0 ? (
      <ExcelFile
        filename={`ArtikelenExport-${moment().format("DD-MM-YYYY")}`}
        element={<ConvertButton data={this.state.convertedData} />}
      >
        <ExcelSheet dataSet={this.state.convertedData} name="Artikelen Overzicht" />
      </ExcelFile>
    ) : (
      <ConvertButton data={this.state.convertedData} />
    );
  }
}
