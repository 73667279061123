import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Table,
  Spinner,
  Button,
  Pagination
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import moment from "moment";
import styles from "./Logboek.module.scss";

export default () => {
  const [logboekData, setLogboekData] = useState([]);
  const [filter, setFilter] = useState("");
  const [fetching, setFetching] = useState(false);
  const [curPage, setCurPage] = useState(1);

  const fetch = async () => {
    setFetching(true);
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/logboek")
      .then(res => {
        convertData(res.data);
      })
      .catch(err => console.error(err));
    setFetching(false);
  };

  const convertData = data => {
    const convertedWithDate = data.map(log => {
      log.ActieDatum = moment(log.ActieDatum).format("DD/MM/YYYY | HH:mm:ss");
      return log;
    });
    const size = 25;
    let counter = 0;
    let splitItems = [];
    let splitItem = [];
    if (data.length < size) splitItems[0] = data;
    else
      convertedWithDate.forEach(item => {
        if (counter === size) {
          splitItems.push(splitItem);
          splitItem = [];
          counter = 0;
        }
        if (counter < size) {
          splitItem.push(item);
        }
        counter++;
      });
    setLogboekData(splitItems);
  };

  useEffect(() => {
    fetch();
    return () => {
      setLogboekData([]);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="p-0 shadow">
      <Container className="bg-primary rounded-top p-3" fluid>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={6}>
            <h1 className="m-0 text-white mb-3 mb-md-0">Logboek</h1>
          </Col>
        </Row>
      </Container>
      <Container className="bg-light p-4 rounded-bottom" fluid>
        <InputGroup className="mb-4" size="lg">
          <InputGroup.Prepend>
            <InputGroup.Text id="zoekfilter">
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Type hier om te filteren..."
            aria-label="Artikelgroep zoekfilter"
            aria-describedby="zoekfilter"
            onChange={e => setFilter(e.target.value)}
          />
          <InputGroup.Append>
            <Button variant="secondary" onClick={fetch} className={styles.SyncBtn}>
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {logboekData.length > 0 && !fetching ? (
          <>
            <Pagination className="d-flex justify-content-center">
              <PaginationItems
                amount={logboekData.length}
                setPage={setCurPage}
                activePage={curPage}
              />
            </Pagination>
            <Table
              bordered
              size="sm"
              striped
              className="bg-white m-0 font-weight-normal"
              responsive
            >
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Level</th>
                  <th>GebruikerID</th>
                  <th>Activiteit</th>
                  <th>Apparaat</th>
                </tr>
              </thead>
              <tbody>
                {logboekData[curPage - 1]
                  .filter(log => {
                    log.filterThrough = false;
                    if (filter.length > 0) {
                      // eslint-disable-next-line
                      for (let index in log) {
                        if (log[index] !== null && log.filterThrough === false) {
                          log.filterThrough = log[index]
                            .toString()
                            .toLowerCase()
                            .includes(filter.toString().toLowerCase());
                        }
                      }
                      return log.filterThrough;
                    } else return true;
                  })
                  .map(log => {
                    return (
                      <tr key={log.ActieDatum}>
                        <td>{log.ActieDatum}</td>
                        <td>{log.Level}</td>
                        <td>{log.GebruikerID}</td>
                        <td>{log.Activiteit}</td>
                        <td>{log.UserAgent}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </Container>
    </Container>
  );
};

const PaginationItems = ({ amount, activePage = 1, setPage }) => {
  let items = [];
  for (let i = 1; i <= amount; i++) {
    items.push(
      <Pagination.Item key={i} active={i === activePage} onClick={() => setPage(i)}>
        {i}
      </Pagination.Item>
    );
  }
  return (
    <>
      <Pagination.First onClick={() => setPage(1)} style={{ marginRight: 10 }} />

      {items}
      <Pagination.Last onClick={() => setPage(amount)} style={{ marginLeft: 10 }} />
    </>
  );
};
