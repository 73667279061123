import React, { useState, useEffect } from "react";
import { Col, FormControl, Row, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus as AddLeverancierIcon,
  faTrash as RemoveLeverancierIcon
} from "@fortawesome/free-solid-svg-icons";

const MAX_LEVERANCIERS = process.env.REACT_APP_MAX_LEVERANCIERS || 1;
export default ({ data, setLeveranciers }) => {
  const [leverancierIdsMetPrijs, setLeverancierIdsMetPrijs] = useState([]);

  useEffect(() => {
    setLeveranciers(leverancierIdsMetPrijs);
    return () => {
      setLeveranciers([]);
    };
    // eslint-disable-next-line
  }, [leverancierIdsMetPrijs]);

  const HandleAddLeverancier = () => {
    if (leverancierIdsMetPrijs.length >= MAX_LEVERANCIERS) return;
    const obj = {
      objId: Math.random()
        .toString(36)
        .substr(2, 9),
      leverancierId: data[0].ID
    };
    let _list = [...leverancierIdsMetPrijs];
    _list.push(obj);
    setLeverancierIdsMetPrijs(_list);
  };

  const HandleRemoveLeverancier = () => {
    let _list = [...leverancierIdsMetPrijs];
    _list.pop();
    setLeverancierIdsMetPrijs(_list);
  };

  const HandleSelectLeverancier = (id, e) => {
    const leverancierId = [...data][e.target.selectedIndex].ID;
    let _list = [...leverancierIdsMetPrijs];
    _list = _list.map(item => {
      item.leverancierId = item.objId === id ? leverancierId : item.leverancierId;
      return item;
    });
    setLeverancierIdsMetPrijs(_list);
  };


  return (
    <Col xs={12}>
      <hr />
      <Row className="mb-3">
        <Col xs={8} md={10} xl={11} className="d-flex justify-content-start align-items-center">
          <p className="font-weight-bold m-0">
            Leveranciers beheren ({leverancierIdsMetPrijs.length}/{MAX_LEVERANCIERS})
          </p>
        </Col>
        <Col xs={4} md={2} xl={1}>
          <Button
            block
            variant="outline-success"
            onClick={HandleAddLeverancier}
            disabled={leverancierIdsMetPrijs.length >= MAX_LEVERANCIERS}
          >
            <FontAwesomeIcon icon={AddLeverancierIcon} />
          </Button>
        </Col>
      </Row>
      {leverancierIdsMetPrijs.map((leverancier, index) => {
        const last = index + 1 === leverancierIdsMetPrijs.length;
        return (
          <Row key={leverancier.objId}>
            <Col xs={12} md={12}>
              <FormControl
                as="select"
                onChange={e => HandleSelectLeverancier(leverancier.objId, e)}
              >
                {data.map(({ Leverancier }) => {
                  return <option key={Leverancier}>{Leverancier}</option>;
                })}
              </FormControl>
            </Col>
            {last ? (
              <Col xs={4} md={2} xl={1}>
                <Button variant="outline-danger" block onClick={HandleRemoveLeverancier}>
                  <FontAwesomeIcon icon={RemoveLeverancierIcon} />
                </Button>
              </Col>
            ) : null}
          </Row>
        );
      })}
    </Col>
  );
};
