import React, { Component } from "react";
import {
  Col,
  Container,
  InputGroup,
  Table,
  Spinner,
  Row,
  FormControl,
  Button,
  Modal,
  Form,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as editIcon,
  faTrash as deleteIcon,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../../components/auth/PermissionValidation";

const API = process.env.REACT_APP_AUTH_API_URI;
export default class Beheereigenaar extends Component {
  state = {
    data: { eigenaren: [] },
    isFetching: false,
    showDeleteModal: false,
    showAddModal: false,
    showEditModal: false,
    isDeleting: false,
    isAdding: false,
    isEditing: false,
    eigenaarToDelete: 0,
    eigenaarToEdit: 0,
    eigenaarAfkorting: "",
    currentEigenaarAfkorting: "",
    eigenaarVolledig: "",
    currentEigenaarVolledig: "",
    eigenaarStraat: "",
    currentEigenaarStraat: "",
    eigenaarHuisnummer: 0,
    currentEigenaarHuisnummer: 0,
    eigenaarPostcode: "",
    currentEigenaarPostcode: "",
    eigenaarPlaats: "",
    currentEigenaarPlaats: "",
    filter: ""
  };

  GetEigenaarData = async () => {
    this.setState({ isFetching: true });
    await Promise.all([Axios.get(API + "/eigenaren")]).then(res => {
      this.setState({
        data: {
          eigenaren: res[0].data
        },
        isFetching: false
      });
    });
  };

  DeleteEigenaarData = async () => {
    this.setState({ isDeleting: true });
    await Axios.delete(API + "/eigenaren/" + this.state.eigenaarToDelete)
      .then(res => {
        this.GetEigenaarData();
      })
      .catch(err => {
        this.GetEigenaarData();
      });
    this.setState({ showDeleteModal: false, isDeleting: false, eigenaarToDelete: 0 });
  };

  AddEigenaarData = async event => {
    event.preventDefault();
    this.setState({ isAdding: true });
    await Axios.post(API + "/eigenaren", {
      afkorting: this.state.eigenaarAfkorting,
      volledig: this.state.eigenaarVolledig,
      straat: this.state.eigenaarStraat,
      huisnummer: this.state.eigenaarHuisnummer,
      postcode: this.state.eigenaarPostcode,
      plaats: this.state.eigenaarPlaats
    })
      .then(res => this.GetEigenaarData())
      .catch(this.GetEigenaarData());
    this.setState({ showAddModal: false, isAdding: false });
  };

  EditEigenaarData = async event => {
    event.preventDefault();
    this.setState({
      isEditing: true
    });
    await Axios.put(API + "/eigenaren/" + this.state.eigenaarToEdit, {
      afkorting: this.state.eigenaarAfkorting,
      volledig: this.state.eigenaarVolledig,
      straat: this.state.eigenaarStraat,
      huisnummer: this.state.eigenaarHuisnummer,
      postcode: this.state.eigenaarPostcode,
      plaats: this.state.eigenaarPlaats
    })
      .then(res => this.GetEigenaarData())
      .catch(this.GetEigenaarData());
    this.setState({ showEditModal: false, isEditing: false });
  };

  componentDidMount() {
    this.GetEigenaarData();
  }

  render() {
    return (
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Eigenaren</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right" md={12} lg={10} xl={8}>
                <Button
                  className="float-right"
                  variant="outline-light"
                  onClick={() => this.setState({ showAddModal: true })}
                  block
                >
                  Nieuwe eigenaar toevoegen
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Eigenaar zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => this.setState({ filter: e.target.value })}
            />
          </InputGroup>
          <Col className="p-0">
            {this.state.isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>Afkorting</th>
                    <th>Volledig</th>
                    <th>Straat</th>
                    <th>Huisnummer</th>
                    <th>Postcode</th>
                    <th>Plaats</th>
                    {PermissionValidation("kanWijzigen" || "kanVerwijderen", <th>Acties</th>)}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.eigenaren
                    .filter(e => {
                      e.filterThrough = false;
                      if (this.state.filter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in e) {
                          if (e[index] !== null && e.filterThrough === false) {
                            e.filterThrough = e[index]
                              .toString()
                              .toLowerCase()
                              .includes(this.state.filter.toString().toLowerCase());
                          }
                        }
                        return e.filterThrough;
                      } else return true;
                    })
                    .map(eigenaar => {
                      return (
                        <tr key={eigenaar.ID}>
                          <td>{eigenaar.Afkorting}</td>
                          <td>{eigenaar.Volledig}</td>
                          <td>{eigenaar.Straat}</td>
                          <td>{eigenaar.Huisnummer}</td>
                          <td>{eigenaar.Postcode}</td>
                          <td>{eigenaar.Plaats}</td>
                          {PermissionValidation(
                            "kanWijzigen" || "kanVerwijderen",
                            <td>
                              {PermissionValidation(
                                "kanWijzigen",
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() =>
                                    this.setState({
                                      eigenaarToEdit: eigenaar.ID,
                                      showEditModal: true,
                                      currentEigenaarAfkorting: eigenaar.Afkorting,
                                      currentEigenaarVolledig: eigenaar.Volledig,
                                      currentEigenaarStraat: eigenaar.Straat,
                                      currentEigenaarHuisnummer: eigenaar.Huisnummer,
                                      currentEigenaarPostcode: eigenaar.Postcode,
                                      currentEigenaarPlaats: eigenaar.Plaats,
                                      eigenaarAfkorting: eigenaar.Afkorting,
                                      eigenaarVolledig: eigenaar.Volledig,
                                      eigenaarStraat: eigenaar.Straat,
                                      eigenaarHuisnummer: eigenaar.Huisnummer,
                                      eigenaarPostcode: eigenaar.Postcode,
                                      eigenaarPlaats: eigenaar.Plaats
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={editIcon} />
                                </Button>
                              )}
                              &nbsp;
                              {PermissionValidation(
                                "kanVerwijderen",
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      eigenaarToDelete: eigenaar.ID,
                                      showDeleteModal: true
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={deleteIcon} />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
        {/* DELETE EIGENAAR */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-delete"
          centered
          onHide={() => this.setState({ showDeleteModal: false })}
          show={this.state.showDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-delete">
              Eigenaar verwijderen
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Weet u zeker dat u deze eigenaar wilt verwijderen? Deze actie kan niet ongedaan
              gemaakt worden.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showDeleteModal: false })} variant="dark">
              Annuleren
            </Button>
            <Button onClick={this.DeleteEigenaarData} variant="danger">
              Verwijderen
              {this.state.isDeleting ? (
                <Spinner variant="white" animation="border" size="sm" className="ml-1" />
              ) : null}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* EDIT EIGENAAR */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showEditModal: false })}
          show={this.state.showEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Eigenaar bewerken</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.EditEigenaarData}>
            <Modal.Body>
              <Form.Row>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Afkorting</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de afkorting in.."
                      required
                      onChange={e => this.setState({ eigenaarAfkorting: e.target.value })}
                      defaultValue={this.state.currentEigenaarAfkorting}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Volledige naam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de volledige naam in.."
                      onChange={e => this.setState({ eigenaarVolledig: e.target.value })}
                      defaultValue={this.state.currentEigenaarVolledig}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Straatnaam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de straat in.."
                      required
                      onChange={e => this.setState({ eigenaarStraat: e.target.value })}
                      defaultValue={this.state.currentEigenaarStraat}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Huisnummer</FormLabel>
                    <FormControl
                      type="number"
                      placeholder="Vul hier het huisnummer in.."
                      required
                      min={0}
                      onChange={e => this.setState({ eigenaarHuisnummer: e.target.value })}
                      defaultValue={this.state.currentEigenaarHuisnummer}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Postcode</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de postcode in.."
                      required
                      onChange={e => this.setState({ eigenaarPostcode: e.target.value })}
                      defaultValue={this.state.currentEigenaarPostcode}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Plaats</FormLabel>
                    <FormControl
                      type="text"
                      required
                      placeholder="Vul hier de plaats in.."
                      onChange={e => this.setState({ eigenaarPlaats: e.target.value })}
                      defaultValue={this.state.currentEigenaarPlaats}
                    />
                  </FormGroup>
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showEditModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Bewerken
                {this.state.isEditing ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* ADD EIGENAAR */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showAddModal: false })}
          show={this.state.showAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Eigenaar toevoegen</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.AddEigenaarData}>
            <Modal.Body>
              <Form.Row>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Afkorting</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de afkorting in.."
                      required
                      onChange={e => this.setState({ eigenaarAfkorting: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Volledige naam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de volledige naam in.."
                      onChange={e => this.setState({ eigenaarVolledig: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Straatnaam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de straatnaam in.."
                      required
                      onChange={e => this.setState({ eigenaarStraat: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Huisnummer</FormLabel>
                    <FormControl
                      type="number"
                      placeholder="Vul hier het huisnummer in.."
                      min={0}
                      required
                      onChange={e => this.setState({ eigenaarHuisnummer: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Postcode</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de postcode in.."
                      required
                      onChange={e => this.setState({ eigenaarPostcode: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormLabel>Plaats</FormLabel>
                    <FormControl
                      type="text"
                      required
                      placeholder="Vul hier de plaats in.."
                      onChange={e => this.setState({ eigenaarPlaats: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showAddModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Toevoegen
                {this.state.isAdding ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    );
  }
}
