import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
class GebruikerWijzigen extends Component {
  state = {
    leavePage: false,
    isFetching: false,
    email: "",
    voornaam: "",
    tussenvoegsel: "",
    achternaam: "",
    telefoonnummer: "",
    actief: 0,
    initialActive: 0,
    geblokkeerd: 0
  };

  userId = this.props.match.params.id;

  async componentDidMount() {
    this.setState({ isFetching: true });
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/gebruikers/" + this.userId)
      .then(res => {
        const g = { ...res.data[0] };
        this.setState({
          email: g.Email,
          voornaam: g.Voornaam,
          tussenvoegsel: g.Tussenvoegsel,
          achternaam: g.Achternaam,
          telefoonnummer: g.Telefoonnummer,
          actief: g.Actief,
          initialActive: g.Actief,
          geblokkeerd: g.Geblokkeerd
        });
      })
      .catch(err => console.error(err));
    this.setState({ isFetching: false });
  }

  HandleSubmit = async e => {
    if (e) e.preventDefault();
    await Axios.put(process.env.REACT_APP_AUTH_API_URI + "/gebruikers/" + this.userId, {
      email: this.state.email,
      voornaam: this.state.voornaam,
      tussenvoegsel: this.state.tussenvoegsel,
      achternaam: this.state.achternaam,
      telefoonnummer: this.state.telefoonnummer,
      actief: this.state.actief,
      geblokkeerd: this.state.geblokkeerd
    })
      .then(res => {
        toast.success(res.data.message);
        this.setState({ leavePage: true });
      })
      .catch(err => console.error(err));
  };

  render() {
    return (
      <Container className="p-5">
        {this.state.leavePage ? <Redirect to="/gebruikers" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Gebruiker wijzigen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ leavePage: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              <small className="text-muted">
                <i>Deze acties worden pas toegepast bij het doorvoeren van de wijzigingen.</i>
              </small>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={4}>
                  <Button
                    block
                    variant={this.state.geblokkeerd === 1 ? "outline-danger" : "danger"}
                    onClick={() =>
                      this.setState({ geblokkeerd: this.state.geblokkeerd === 1 ? 0 : 1 })
                    }
                  >
                    {this.state.geblokkeerd === 1 ? "Deblokkeer" : "Blokkeer"} gebruiker
                  </Button>
                </Col>
                <Col xs={0} md={4} className="d-none d-md-block">
                  &nbsp;
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Button
                    block
                    variant={this.state.actief === 1 ? "outline-dark" : "dark"}
                    onClick={() => this.setState({ actief: this.state.actief === 1 ? 0 : 1 })}
                    disabled={this.state.initialActive !== 1}
                  >
                    {this.state.actief === 1 ? "Deactiveer" : "Activeer"} gebruiker
                  </Button>
                </Col>
              </Row>
              <hr />
              <Form onSubmit={this.HandleSubmit}>
                <Form.Row>
                  <Col md={4}>
                    <FormLabel>Voornaam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier de voornaam van de gebruiker in"
                      required
                      onChange={e => this.setState({ voornaam: e.target.value })}
                      defaultValue={this.state.voornaam}
                    />
                  </Col>
                  <Col md={3}>
                    <FormLabel>Tussenvoegsel</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier het tussenvoegsel van de gebruiker in"
                      onChange={e => this.setState({ tussenvoegsel: e.target.value })}
                      defaultValue={this.state.tussenvoegsel}
                    />
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <FormLabel>Achternaam</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier de achternaam van de gebruiker in"
                        required
                        onChange={e => this.setState({ achternaam: e.target.value })}
                        defaultValue={this.state.achternaam}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>E-mail adres</FormLabel>
                      <FormControl
                        type="email"
                        placeholder="Vul hier het e-mail adres van de gebruiker in"
                        required
                        onChange={e => this.setState({ email: e.target.value })}
                        defaultValue={this.state.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Telefoonnummer</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier het telefoonnummer van de gebruiker in"
                        required
                        onChange={e => this.setState({ telefoonnummer: e.target.value })}
                        defaultValue={this.state.telefoonnummer}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <hr />
                <Button type="submit" block>
                  Wijzigingen opslaan
                </Button>
              </Form>
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default GebruikerWijzigen;
