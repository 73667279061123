import React from "react";
import styles from "./Lenen.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt as UitleenIcon,
  faSignInAlt as InneemIcon,
  faClipboardList as OverzichtIcon,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";

const LeenButton = props => {
  return (
    <div className={[styles.LeenButton, "shadow"].join(" ")} onClick={props.clickAction}>
      <FontAwesomeIcon icon={props.icon || faQuestion} className={styles.LeenButtonIcon} />
      <p className="m-0">{props.text || "No Text Specified"}</p>
    </div>
  );
};

export default props => {
  const [toInnemen, setToInnemen] = React.useState(false);
  const [toUitlenen, setToUitlenen] = React.useState(false);
  const [toOverzicht, setToOverzicht] = React.useState(false);

  return (
    <div className={styles.Center}>
      {toUitlenen ? <Redirect to="/lenen/uitlenen" /> : null}
      {toInnemen ? <Redirect to="/lenen/innemen" /> : null}
      {toOverzicht ? <Redirect to="/lenen/overzicht" /> : null}
      <LeenButton icon={InneemIcon} text="Innemen" clickAction={() => setToInnemen(true)} />
      <LeenButton icon={UitleenIcon} text="Uitlenen" clickAction={() => setToUitlenen(true)} />
      <LeenButton icon={OverzichtIcon} text="Overzicht" clickAction={() => setToOverzicht(true)} />
    </div>
  );
};
