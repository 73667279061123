import React from "react";
import jwt from "jsonwebtoken";
import { Redirect, withRouter } from "react-router-dom";

const AuthenticationProvider = props => {
  const _token = localStorage.getItem("access-token");
  const _refreshToken = localStorage.getItem("refresh-token");

  const ExceptionFound = () => {
    if (_token) localStorage.removeItem("access-token");
    if (_refreshToken) localStorage.removeItem("refresh-token");
  };

  if ((!_token || !_refreshToken)) {
    ExceptionFound();
    return <Redirect to="/login" />;
  }

  const _decodeRefreshToken = jwt.decode(_refreshToken);
  if (Date.now() >= _decodeRefreshToken.exp * 1000) {
    ExceptionFound();
  }

  return props.children;
};

export default withRouter(AuthenticationProvider);
