import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import {
  Col,
  Container,
  Button,
  Row,
  Form,
  FormControl,
  FormLabel,
  FormGroup
} from "react-bootstrap";
import styles from "./GebruikerSetup.module.scss";
import Axios from "axios";
import { toast } from "react-toastify";

export default ({ hideNavbar }) => {
  hideNavbar(true);
  const { user } = jwt.decode(localStorage.getItem("access-token"));
  const [toHome, setToHome] = useState(false);
  // -- userdata
  const [voornaam, setVoornaam] = useState("");
  const [tussenvoegsel, setTussenvoegsel] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [telefoonnummer, setTelefoonnummer] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");

  const FetchUser = async () => {
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/auth/setup/" + user.ID)
      .then(({ data }) => {
        setVoornaam(data.Voornaam);
        setTussenvoegsel(data.Tussenvoegsel);
        setAchternaam(data.Achternaam);
      })
      .catch(err => console.error(err));
  };

  const HandleSubmit = async event => {
    if (event) event.preventDefault();
    if (password !== conPassword) return toast.warn("De wachtwoorden zijn niet gelijk.");
    await Axios.post(process.env.REACT_APP_AUTH_API_URI + "/auth/setup/" + user.ID, {
      voornaam: voornaam,
      tussenvoegsel: tussenvoegsel,
      achternaam: achternaam,
      telefoonnummer: telefoonnummer,
      wachtwoord: password
    })
      .then(res => {
        toast.success(res.data.message);
        setToHome(true);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    FetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.Wrapper}>
      {toHome ? <Redirect to="/" /> : null}
      <div className={styles.Afbeelding} />
      <Container className="p-5">
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col xs={12}>
                <h1 className="m-0 text-white mb-3 mb-md-0 text-center">Profiel opstellen</h1>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              <h5>
                Beste {user.naam}, welkom bij SportOpslag.nl. Laten we om te beginnen uw profiel
                opstellen, u wordt vriendelijk verzocht om onderstaande gegevens correct in te
                vullen. Deze gegevens kunnen altijd nog gewijzigd worden mocht er iets achteraf niet
                kloppen.
              </h5>
              <hr />
              <Form onSubmit={HandleSubmit}>
                <p>
                  U wordt verzocht om alle onderstaande gegevens te controleren en deze in te vullen
                  indien nodig
                </p>
                <Form.Row>
                  <Col md={6}>
                    <FormLabel>Voornaam</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier uw voornaam in"
                      required
                      onChange={e => setVoornaam(e.target.value)}
                      value={voornaam}
                    />
                  </Col>
                  <Col md={6}>
                    <FormLabel>Tussenvoegsel</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Vul hier uw tussenvoegsel in"
                      onChange={e => setTussenvoegsel(e.target.value)}
                      value={tussenvoegsel}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Achternaam</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier uw achternaam in"
                        required
                        onChange={e => setAchternaam(e.target.value)}
                        value={achternaam}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Telefoonnummer</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier uw telefoonnummer in"
                        required
                        onChange={e => setTelefoonnummer(e.target.value)}
                        value={telefoonnummer}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Nieuw wachtwoord</FormLabel>
                      <FormControl
                        type="password"
                        placeholder="Vul hier uw nieuwe wachtwoord in"
                        required
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Nieuw wachtwoord (herhalen)</FormLabel>
                      <FormControl
                        type="password"
                        placeholder="Vul hier uw nieuwe wachtwoord in"
                        required
                        onChange={e => setConPassword(e.target.value)}
                        value={conPassword}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <hr />
                <Button
                  type="submit"
                  block
                  variant="secondary"
                  disabled={telefoonnummer === "" || password === "" || conPassword === ""}
                >
                  Profiel voltooien
                </Button>
              </Form>
            </Col>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
