import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button } from "react-bootstrap";
import {
  faTags as ArtikelIcon,
  faFileAlt as InfoIcon,
  faWrench as RepairIcon,
  faTrash as DeleteIcon
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ArtikelDefectRepair from "./ArtikelDefectRepair";
import ArtikelDefectDelete from "./ArtikelDefectDelete";

export default ({ artikelDefecten, toggle, variant, fetch }) => {
  // -- modals
  const [showRepair, setShowRepair] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  // -- props
  const [artikelNr, setArtikelNr] = useState(null);
  const [datumDefect, setDatumDefect] = useState(null);

  const _v = variant === "defect";

  const HandleRepair = (artikelNr, datumDefect) => {
    if (!showRepair && artikelNr !== null) {
      setArtikelNr(artikelNr);
      setDatumDefect(datumDefect);
      setShowRepair(true);
    } else {
      setArtikelNr(null);
      setDatumDefect(null);
      setShowRepair(false);
    }
  };

  const HandleDelete = (artikelNr, datumDefect) => {
    if (!showDelete && artikelNr !== null) {
      setArtikelNr(artikelNr);
      setDatumDefect(datumDefect);
      setShowDelete(true);
    } else {
      setArtikelNr(null);
      setDatumDefect(null);
      setShowDelete(false);
    }
  };

  return (
    <>
      {_v ? (
        <ArtikelDefectRepair
          shown={showRepair}
          toggle={HandleRepair}
          anr={artikelNr}
          datDefect={datumDefect}
          fetch={fetch}
        />
      ) : (
        <ArtikelDefectDelete
          shown={showDelete}
          toggle={HandleDelete}
          anr={artikelNr}
          datDefect={datumDefect}
          fetch={fetch}
        />
      )}
      <Table bordered size="sm" striped className="bg-white m-0 font-weight-normal" responsive>
        <thead>
          <tr>
            <th>Artikelnummer</th>
            <th>Aantal</th>
            <th>Opmerkingen {_v ? "" : "na reparatie"}</th>
            <th>Datum Defect</th>
            <th>Datum Reparatie</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {artikelDefecten.map(
            ({
              Artikelnummer,
              Aantal,
              Opmerkingen,
              OpmerkingenReparatie,
              DatumDefect,
              DatumReparatie
            }) => {
              return (
                <tr key={Artikelnummer}>
                  <td>
                    <Button size="sm" variant="outline-dark" onClick={() => toggle(Artikelnummer)}>
                      <FontAwesomeIcon icon={InfoIcon} />
                    </Button>
                    <FontAwesomeIcon
                      icon={ArtikelIcon}
                      className="ml-2 mr-1"
                      style={{ opacity: 0.3 }}
                      title="Artikelnummer"
                    />
                    {Artikelnummer}
                  </td>
                  <td>{Aantal}</td>
                  <td>{_v ? Opmerkingen : OpmerkingenReparatie}</td>
                  <td>{moment(DatumDefect).format("DD-MM-YYYY")}</td>
                  <td>
                    {DatumReparatie !== null
                      ? moment(DatumReparatie).format("DD-MM-YYYY")
                      : "N.v.t."}
                  </td>
                  <td>
                    {_v ? (
                      <Button
                        size="sm"
                        variant="success"
                        block
                        onClick={() =>
                          HandleRepair(Artikelnummer, moment(DatumDefect).format("YYYY-MM-DD"))
                        }
                      >
                        <FontAwesomeIcon icon={RepairIcon} />
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="danger"
                        block
                        onClick={() =>
                          HandleDelete(Artikelnummer, moment(DatumDefect).format("YYYY-MM-DD"))
                        }
                      >
                        <FontAwesomeIcon icon={DeleteIcon} />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
    </>
  );
};
