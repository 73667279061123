import React from "react";
import {
  Table,
  Container,
  InputGroup,
  Button,
  FormControl,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Modal
} from "react-bootstrap";
import { withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle as infoIcon,
  faPen as editIcon,
  faTrash as deleteIcon,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../components/auth/PermissionValidation";
import { toast } from "react-toastify";

const Magazijnen = props => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [magazijnData, setMagazijnenData] = React.useState([]);
  const [toMagazijnToevoegen, setToMagazijnToevoegen] = React.useState(false);
  const [toMagazijnWijzigen, setToMagazijnWijzigen] = React.useState(false);
  const [magazijnToDelete, setMagazijnToDelete] = React.useState(0);
  const [magazijnToEdit, setMagazijnToEdit] = React.useState(0);
  const [filter, setFilter] = React.useState("");

  const GetMagazijnData = () => {
    setIsFetching(true);
    Axios.get(process.env.REACT_APP_AUTH_API_URI + "/magazijnen", {
      timeout: 3000
    })
      .then(response => {
        setIsFetching(false);
        if (response.status === 200) setMagazijnenData(response.data);
      })
      .catch(error => {
        setIsFetching(false);
      });
  };

  React.useEffect(() => {
    GetMagazijnData();
    return () => {};
  }, []);

  const DeleteMagazijn = async () => {
    setIsDeleting(true);
    await Axios.delete(process.env.REACT_APP_AUTH_API_URI + "/magazijnen/" + magazijnToDelete, {
      timeout: 3000
    })
      .then(response => {
        GetMagazijnData();
      })
      .catch(error => {
        toast.error(error.response.data.message);
        GetMagazijnData();
      });
    setIsDeleting(false);
    setShowDeleteModal(false);
    setMagazijnToDelete(0);
  };

  const EditMagazijn = id => {
    setMagazijnToEdit(id);
    setToMagazijnWijzigen(true);
  };

  return (
    <Container className="p-5" fluid>
      {toMagazijnToevoegen ? <Redirect to="/magazijnen/toevoegen" /> : null}
      {toMagazijnWijzigen ? <Redirect to={"/magazijnen/wijzigen/" + magazijnToEdit} /> : null}
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Magazijnen</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right" md={10} lg={6}>
                {PermissionValidation(
                  "kanToevoegen",
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => setToMagazijnToevoegen(true)}
                  >
                    Nieuw magazijn toevoegen
                  </Button>
                )}
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Magazijn zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => setFilter(e.target.value)}
            />
          </InputGroup>
          <Col className="p-0">
            {isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="barcode-tooltip">Dit is een magazijn.</Tooltip>}
                      >
                        <div>
                          Magazijn
                          <FontAwesomeIcon icon={infoIcon} className="ml-2 text-muted" />
                        </div>
                      </OverlayTrigger>
                    </th>
                    <th>Eigenaar magazijn</th>
                    <th>Opmerkingen</th>
                    {PermissionValidation("kanWijzigen" || "kanVerwijderen", <th>Acties</th>)}
                  </tr>
                </thead>
                <tbody>
                  {magazijnData
                    .filter(m => {
                      m.filterThrough = false;
                      if (filter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in m) {
                          if (m[index] !== null && m.filterThrough === false) {
                            m.filterThrough = m[index]
                              .toString()
                              .toLowerCase()
                              .includes(filter.toString().toLowerCase());
                          }
                        }
                        return m.filterThrough;
                      } else return true;
                    })
                    .map((magazijn, i) => {
                      return (
                        <tr key={i}>
                          <td>{magazijn.Magazijn}</td>
                          <td>
                            {magazijn.Afkorting} - {magazijn.Plaats} ({magazijn.Straat}{" "}
                            {magazijn.Huisnummer})
                          </td>
                          <td>{magazijn.Opmerkingen}</td>
                          {PermissionValidation(
                            "kanWijzigen" || "kanVerwijderen",
                            <td>
                              {PermissionValidation(
                                "kanWijzigen",
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() => {
                                    EditMagazijn(magazijn.ID);
                                  }}
                                >
                                  <FontAwesomeIcon icon={editIcon} />
                                </Button>
                              )}
                              &nbsp;
                              {PermissionValidation(
                                "kanVerwijderen",
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    setMagazijnToDelete(magazijn.ID);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={deleteIcon} />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
      </Container>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDeleteModal(false)}
        show={showDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Magazijn verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Weet u zeker dat u dit magazijn wilt verwijderen? Deze actie kan niet ongedaan gemaakt
            worden.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeleteModal(false)} variant="dark">
            Annuleren
          </Button>
          <Button onClick={DeleteMagazijn} variant="danger">
            Verwijderen
            {isDeleting ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default withRouter(Magazijnen);
