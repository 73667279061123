import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default ({ Titel, Omschrijving, ID, LastDate }) => {
  return (
    <Card className="mb-2">
      <Accordion>
        <Card.Header>
          <h4>
            {Titel}
            <Accordion.Toggle as={Button} variant="link" eventKey={ID}>
              <FontAwesomeIcon icon={faInfo} />
            </Accordion.Toggle>
          </h4>
        </Card.Header>
        <Accordion.Collapse eventKey={ID}>
          <Card.Body>
            <p>{Omschrijving}</p>
            <p
              className="w-100 text-right text-muted mb-0 font-italic"
              style={{ fontFamily: "monospace" }}
            >
              Laatst bijgewerkt: {moment(LastDate).format("DD-MM-YYYY, HH:mm:ss")}
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  );
};
