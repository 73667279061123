import React from "react";
import { Accordion, Container, Row, Col, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt as InneemIcon,
  faWarehouse as IngenomenIcon,
  faSignOutAlt as UitleenIcon,
  faTags as ArtikelIcon,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

export default ({
  ID,
  Hal,
  Veld,
  Lener,
  TelefoonnummerLener,
  Items,
  Datumuitgifte,
  handleModal
}) => {
  const HofV = (h, v) => {
    if (h === "" || h === undefined || h === null) return `Veld ${v}`;
    else if (v === "" || v === undefined || v === null) return `Hal ${h}`;
    else return "Hal/Veld";
  };

  return (
    <Accordion className="my-2 rounded shadow-sm">
      <Container
        className="p-0 rounded bg-secondary text-white"
        fluid
        style={{ border: "1px solid rgba(0,0,0,0.1)" }}
      >
        <Row className="p-1 pl-md-2 d-flex align-items-center">
          <Col md={2}>{HofV(Hal, Veld)}</Col>
          <Col md={5}>
            {Lener} ({TelefoonnummerLener})
          </Col>
          <Col md={3}>
            <FontAwesomeIcon icon={UitleenIcon} className="mr-1" />
            {moment(Datumuitgifte).format("DD-MM-YYYY")}
          </Col>
          <Col md={2} className="text-md-right">
            <Accordion.Toggle as={AccordionButton} eventKey={ID} />
            <Button
              size="sm"
              variant="outline-light"
              className="ml-md-2"
              onClick={() => handleModal(ID)}
            >
              <FontAwesomeIcon icon={InneemIcon} />
            </Button>
          </Col>
        </Row>
      </Container>
      <Accordion.Collapse eventKey={ID}>
        <ListGroup className="rounded-0 rounded-bottom">
          {Items.map(i => {
            return (
              <ListGroupItem
                key={i.ID}
                className="d-flex justify-content-between flex-row p-1 px-2"
              >
                <Container fluid className="p-0">
                  <Row>
                    <Col sm={2}>
                      {i.Aantal}&times;{" "}
                      <i className="text-muted">
                        ({i.Aantalingenomen} <FontAwesomeIcon icon={IngenomenIcon} />)
                      </i>
                    </Col>
                    <Col sm={5} className="text-left">
                      <FontAwesomeIcon
                        icon={ArtikelIcon}
                        className="mr-1"
                        style={{ opacity: 0.3 }}
                      />
                      {i.Artikelnummer}
                    </Col>
                    <Col sm={5} className="text-right text-muted">
                      {i.Bijzonderheden}
                    </Col>
                  </Row>
                </Container>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Accordion.Collapse>
    </Accordion>
  );
};

const AccordionButton = props => {
  return (
    <Button size="sm" variant="outline-light" {...props}>
      <FontAwesomeIcon icon={faChevronDown} />
    </Button>
  );
};
