import React, { useState } from "react";
import { Modal, Button, FormGroup, FormLabel, FormControl, Col, Form } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";

export default ({ shown, toggle, email }) => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const HandleSubmit = async e => {
    if (e) e.preventDefault();
    if (password !== confirmPassword) return toast.warn("De wachtwoorden zijn niet gelijk..");
    await Axios.post(process.env.REACT_APP_AUTH_API_URI + "/manageprofile/confirm", {
      email: email,
      token: token,
      password: password
    })
      .then(res => {
        toast.success(res.data.message);
        toggle();
      })
      .catch(err => console.error(err));
  };

  return (
    <Modal show={shown} onHide={toggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Wachtwoord herstellen</Modal.Title>
      </Modal.Header>
      <Form onSubmit={HandleSubmit}>
        <Modal.Body>
          <p>
            U heeft als het goed is een code ontvangen via de mail. Als u onze mail niet kunt
            vinden, kijk dan voor de zekerheid in uw spam-map.
          </p>
          <Form.Row>
            <Col xs={12}>
              <FormGroup>
                <FormLabel>Tijdelijke code</FormLabel>
                <FormControl
                  type="text"
                  required
                  placeholder="Vul hier uw tijdelijke code in"
                  onChange={e => setToken(e.target.value)}
                  value={token}
                />
              </FormGroup>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={6}>
              <FormLabel>Nieuw wachtwoord</FormLabel>
              <FormControl
                type="password"
                required
                placeholder="Vul hier uw nieuwe wachtwoord in"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </Col>
            <Col md={6} className="mt-3 mt-md-0">
              <FormLabel>Wachtwoord (herhalen)</FormLabel>
              <FormControl
                type="password"
                required
                placeholder="Vul hier uw nieuwe wachtwoord (herhaald) in"
                onChange={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={toggle}>
            Annuleren
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={token === "" || confirmPassword !== password}
          >
            Controleren
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
