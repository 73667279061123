import React, { useState } from "react";
import { Accordion, Container, Row, Col, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt as InneemIcon,
  faWarehouse as IngenomenIcon,
  faSignOutAlt as UitleenIcon,
  faTags as ArtikelIcon,
  faChevronDown,
  faFileAlt as InfoIcon
} from "@fortawesome/free-solid-svg-icons";
import OverzichtItemDelete from "./OverzichtItemDelete";
import ArtikelInfo from "../../Artikelen/ArtikelInfo";

const HofV = u => {
  const { Hal: h, Veld: v } = u;
  if (h === "" || h === undefined || h === null) return `Veld ${v}`;
  else if (v === "" || v === undefined || v === null) return `Hal ${h}`;
  else return "Hal/Veld";
};

export default props => {
  const { uitgifte: u } = props;
  const _v = props.variant === "open";

  const [showArtikelInfo, setShowArtikelInfo] = useState(false);
  const [artikelArtikelNummer, setArtikelArtikelnummer] = useState(null);

  const handleShowArtikelInfo = id => {
    if (id !== null) {
      setArtikelArtikelnummer(id);
      setShowArtikelInfo(true);
    }
  };

  const handleHideArtikelInfo = () => {
    setArtikelArtikelnummer(null);
    setShowArtikelInfo(false);
  };

  return (
    <>
      <ArtikelInfo
        shown={showArtikelInfo}
        artikelNr={artikelArtikelNummer}
        close={handleHideArtikelInfo}
      />
      <Accordion className="my-2 rounded shadow-sm">
        <Container
          className="p-0 rounded bg-secondary text-white"
          fluid
          style={{ border: "1px solid rgba(0,0,0,0.1)" }}
        >
          <Row className="p-1 pl-md-2 d-flex align-items-center">
            <Col md={2}>{HofV(u)}</Col>
            <Col md={5}>
              {u.Lener} ({u.TelefoonnummerLener})
            </Col>
            <Col md={3}>
              <FontAwesomeIcon icon={_v ? UitleenIcon : InneemIcon} className="mr-1" />
              {moment(_v ? u.Datumuitgifte : u.Datuminname).format("DD-MM-YYYY")}
            </Col>
            <Col md={2} className="text-md-right">
              <OverzichtItemDelete item={u} onComplete={props.onComplete} />
              <Accordion.Toggle as={AccordionButton} eventKey={u.ID} />
            </Col>
          </Row>
        </Container>
        <Accordion.Collapse eventKey={u.ID}>
          <>
            {u.Opmerkingen !== "" && u.Opmerkingen !== null ? (
              <p className="mb-0 p-2 bg-white text-muted font-italic">
                Opmerkingen: {u.Opmerkingen}
              </p>
            ) : null}
            <ListGroup className="rounded-0 rounded-bottom">
              {u.Items.map(i => {
                return (
                  <ListGroupItem
                    key={i.ID}
                    className="d-flex justify-content-between flex-row p-1 px-2"
                  >
                    <Container fluid className="p-0">
                      <Row>
                        <Col sm={2}>
                          {i.Aantal}&times;{" "}
                          <i className="text-muted">
                            ({i.Aantalingenomen} <FontAwesomeIcon icon={IngenomenIcon} />)
                          </i>
                        </Col>
                        <Col sm={5} className="text-left">
                          <Button
                            size="sm"
                            variant="outline-dark"
                            onClick={() => handleShowArtikelInfo(i.Artikelnummer)}
                          >
                            <FontAwesomeIcon icon={InfoIcon} />
                          </Button>
                          <FontAwesomeIcon
                            icon={ArtikelIcon}
                            className="ml-2 mr-1"
                            style={{ opacity: 0.3 }}
                            title="Artikelnummer"
                          />
                          {i.Artikelnummer}
                        </Col>
                        <Col sm={5} className="text-right text-muted">
                          {i.Bijzonderheden}
                        </Col>
                      </Row>
                    </Container>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

const AccordionButton = props => {
  return (
    <Button size="sm" variant="outline-light" {...props}>
      <FontAwesomeIcon icon={faChevronDown} />
    </Button>
  );
};
