import React from "react";
import { Redirect } from "react-router-dom";
import styles from "./NotFound.module.scss";
import { Button } from "react-bootstrap";

const NotFound = props => {
  props.hideNavbar(false);
  const [toHomePage, setToHomepage] = React.useState(false);

  return toHomePage ? (
    <Redirect to="/" />
  ) : (
    <div className={styles.NotFound}>
      <h4 className={styles.Caption}>Oh, hallo daar.. bent u verdwaald?</h4>
      <h1 className={styles.Title}>Deze pagina bestaat (nog) niet..</h1>
      <Button
        style={{ marginTop: "20px" }}
        size="lg"
        variant="outline-primary"
        onClick={() => setToHomepage(true)}
      >
        Naar de homepagina
      </Button>
    </div>
  );
};

export default NotFound;
