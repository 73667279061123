import React, { useState } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// authentication
import AuthenticationProvider from "./components/auth/AuthenticationProvider";
import PermissionValidation from "./components/auth/PermissionValidation";
import AccountSetupRedirect from "./components/auth/AccountSetupRedirect";

// components
import Sidebar from "./components/Sidebar";
import Navigationbar from "./components/Navigationbar";

// pages
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Beheer from "./pages/Beheer/Beheer";
import FAQ from "./pages/FAQ/Faq";
// -- artikelen
import Artikelen from "./pages/Artikelen/Artikelen";
import ArtikelToevoegen from "./pages/Artikelen/ArtikelToevoegen";
import ArtikelWijzigen from "./pages/Artikelen/ArtikelWijzigen";
import ArtikelDefectOverzicht from "./pages/Artikelen/Defect/ArtikelDefectOverzicht";
// -- magazijnen
import Magazijnen from "./pages/Magazijnen/Magazijnen";
import MagazijnToevoegen from "./pages/Magazijnen/MagazijnToevoegen";
import MagazijnWijzigen from "./pages/Magazijnen/MagazijnWijzigen";
// -- gebruikers
import Gebruikers from "./pages/Gebruikers/Gebruikers";
import GebruikerToevoegen from "./pages/Gebruikers/GebruikerToevoegen";
import GebruikerWijzigen from "./pages/Gebruikers/GebruikerWijzigen";
import GebruikerSetup from "./pages/Gebruikers/GebruikerSetup";
// -- uitlenen / innemen
import Lenen from "./pages/Lenen/Lenen";
import Innemen from "./pages/Lenen/Innemen/Innemen";
import Uitlenen from "./pages/Lenen/Uitlenen/Uitlenen";
import Overzicht from "./pages/Lenen/Overzicht/Overzicht";
import Profile from "./pages/Profile/Profile";

// history
const history = createBrowserHistory();
history.listen(location => {
  return location;
});

export default () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const RoutesComponent = () => {
    return (
      <Switch>
        <Route path="/login" component={() => <Login hideNavbar={setHideNavbar} />} />
        <AuthenticationProvider>
          <AccountSetupRedirect>
            <Switch>
              <Route exact path="/" component={() => <Home hideNavbar={setHideNavbar} />} />
              <Route exact path="/artikelen" component={Artikelen} />
              <Route
                exact
                path="/setup"
                component={() => <GebruikerSetup hideNavbar={setHideNavbar} />}
              />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/faq" component={FAQ} />
              {PermissionValidation(
                "kanToevoegen",
                <Route exact path="/artikelen/toevoegen" component={ArtikelToevoegen} />
              )}
              {PermissionValidation(
                "kanWijzigen",
                <Route exact path="/artikelen/wijzigen/:id" component={ArtikelWijzigen} />
              )}
              {PermissionValidation(
                "kanWijzigen",
                <Route exact path="/artikelen/defecten" component={ArtikelDefectOverzicht} />
              )}
              <Route exact path="/magazijnen" component={Magazijnen} />
              {PermissionValidation(
                "kanToevoegen",
                <Route exact path="/magazijnen/toevoegen" component={MagazijnToevoegen} />
              )}
              {PermissionValidation(
                "kanWijzigen",
                <Route exact path="/magazijnen/wijzigen/:id" component={MagazijnWijzigen} />
              )}
              {PermissionValidation("kanLenen", <Route exact path="/lenen" component={Lenen} />)}
              {PermissionValidation(
                "kanLenen",
                <Route exact path="/lenen/uitlenen" component={Uitlenen} />
              )}
              {PermissionValidation(
                "kanLenen",
                <Route exact path="/lenen/innemen" component={Innemen} />
              )}
              {PermissionValidation(
                "kanLenen",
                <Route exact path="/lenen/overzicht" component={Overzicht} />
              )}
              {PermissionValidation(
                "isAdmin",
                <Switch>
                  <Route exact path="/gebruikers" component={Gebruikers} />
                  <Route exact path="/gebruikers/toevoegen" component={GebruikerToevoegen} />
                  <Route exact path="/gebruikers/wijzigen/:id" component={GebruikerWijzigen} />
                  <Route exact path="/beheer" component={Beheer} />
                  <Route component={() => <NotFound hideNavbar={setHideNavbar} />} />
                </Switch>
              )}

              <Route component={() => <NotFound hideNavbar={setHideNavbar} />} />
            </Switch>
          </AccountSetupRedirect>
        </AuthenticationProvider>
      </Switch>
    );
  };

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} newestOnTop={true} />
      <Router>
        {hideNavbar ? (
          <RoutesComponent />
        ) : (
          <>
            <Navigationbar sidebarToggle={handleSidebarToggle} sidebarOpen={sidebarOpen} />
            <Sidebar sidebarToggle={handleSidebarToggle} sidebarOpen={sidebarOpen}>
              <NavbarSpacing />
              <RoutesComponent />
            </Sidebar>
          </>
        )}
      </Router>
    </>
  );
};

const NavbarSpacing = () => {
  return <div style={{ paddingTop: "65px" }} />;
};
