import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

export default props => {
  const [show, setShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setShow(!show);
  };

  const removeItem = async () => {
    setIsDeleting(true);
    await Axios.delete(process.env.REACT_APP_AUTH_API_URI + "/lenen/overzicht/" + props.item.ID)
      .then(res => {
        setIsDeleting(false);
        props.onComplete();
      })
      .catch(err => {
        setIsDeleting(false);
        props.onComplete();
      });
  };

  return (
    <>
      <Button variant="outline-dark" size="sm" className="mr-md-2" onClick={handleClose}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Uitgifte verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet u zeker dat u deze uitgifte wilt verwijderen? Deze actie kan niet ongedaan gemaakt
          worden.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Annuleren
          </Button>
          <Button variant="danger" onClick={removeItem}>
            Verwijderen
            {isDeleting ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
