import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";

const AccountSetupRedirect = ({ children }) => {
  let toastId = null;
  let toastIdCon = null;

  const _token = localStorage.getItem("access-token");
  const { user } = jwt.decode(_token);

  if (user.actief !== 1 && !window.location.href.includes("/setup")) {
    if (!toast.isActive(toastId)) {
      toastId = toast.warn("Uw profiel is nog niet correct ingesteld.", { toastId: "noti_setup" });
    }
    return <Redirect to="/setup" />;
  } else if (user.actief === 1 && window.location.href.includes("/setup")) {
    if (!toast.isActive(toastIdCon)) {
      toastIdCon = toast.warn("Uw profiel is al ingesteld!", { toastId: "noti_confirmed" });
    }
    return <Redirect to="/" />;
  }

  return children;
};

export default withRouter(AccountSetupRedirect);
