import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styles from "./SidebarContent.module.scss";
import NavigationContent from "../data/NavigationContent";
import PermissionValidation from "../components/auth/PermissionValidation";

export default () => {
  return (
    <nav className={styles.SidebarContent}>
      {NavigationContent.map((item, index) => {
        if (item.validate) {
          return (
            <div key={index}>
              {PermissionValidation(
                "isAdmin",
                <SidebarItem item={item} styles={styles} index={index} key={index} />
              )}
            </div>
          );
        } else return <SidebarItem item={item} styles={styles} index={index} key={index} />;
      })}
    </nav>
  );
};

const SidebarItem = ({ item, styles, index }) => {
  return (
    <LinkContainer
      to={item.uri}
      exact={item.exact}
      key={index}
      className={styles.SidebarLink}
      activeClassName={styles.SidebarLinkActive}
    >
      <Nav.Link eventKey={index}>{item.title}</Nav.Link>
    </LinkContainer>
  );
};
