import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";

const API = process.env.REACT_APP_AUTH_API_URI;
export default ({ shown, toggle, anr, datDefect, fetch }) => {
  const [updating, setUpdating] = useState(false);

  const HandleDelete = async () => {
    setUpdating(true);
    await Axios.post(API + "/artikelen/defecten/verwijderen/" + anr, { datumDefect: datDefect })
      .then(res => {
        toast.success(res.data.message);
        fetch();
        toggle();
      })
      .catch(err => {
        console.error(err);
      });
    setUpdating(false);
  };

  return (
    <Modal show={shown} onHide={toggle} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>Artikel reparatie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Weet u zeker dat u deze reparatie wilt verwijderen?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={toggle}>
          Annuleren
        </Button>
        <Button variant="danger" onClick={HandleDelete}>
          Verwijderen
          {updating ? (
            <Spinner variant="white" animation="border" size="sm" className="ml-1" />
          ) : null}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
