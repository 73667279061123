import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import FAQElement from "./FAQElement";

export default withRouter(() => {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/faqs")
        .then(res => setFaqData(res.data))
        .catch(err => console.error(err));
    };
    fetchData();
    return () => {
      setFaqData([]);
    };
  }, []);

  return (
    <Container className="p-5" fluid>
      <Container className="bg-primary rounded-top p-3" fluid>
        <h2 className="m-0 text-white mb-3 mb-md-0">Meest gestelde vragen</h2>
      </Container>
      <Container className="bg-light p-4 rounded-bottom shadow" fluid>
        {faqData.map(faq => {
          return <FAQElement {...faq} key={faq.ID} />;
        })}
      </Container>
    </Container>
  );
});
