import React, { useState } from "react";
import { Modal, Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";

export default ({ shown, toggle, setResetEmail, showNext }) => {
  const [email, setEmail] = useState("");

  const HandleSubmit = async () => {
    await Axios.post(process.env.REACT_APP_AUTH_API_URI + "/manageprofile/vergeten", {
      email: email
    })
      .then(res => {
        setResetEmail(email);
        toast.success(res.data.message);
        toggle();
        showNext();
      })
      .catch(err => toast.error(err.response.data.message));
  };

  return (
    <Modal show={shown} onHide={toggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Wachtwoord vergeten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel>E-mail adres</FormLabel>
          <FormControl
            type="email"
            required
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={toggle}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={HandleSubmit} disabled={email === ""}>
          Volgende
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
