import React from "react";
import Sidebar from "react-sidebar";
import styles from "./Sidebar.module.scss";
import SidebarContentComponent from "./SidebarContent";

export default props => {
  return (
    <Sidebar
      sidebar={<SidebarContentComponent />}
      open={props.sidebarOpen}
      docked={false}
      transitions={true}
      onSetOpen={props.sidebarToggle}
      overlayClassName={styles.Overlay}
      touch={false}
      sidebarClassName={styles.Sidebar}
    >
      {<>{props.children}</>}
    </Sidebar>
  );
};
