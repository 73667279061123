export default [
  {
    title: "Home",
    uri: "/",
    exact: true
  },
  {
    title: "Artikelen",
    uri: "/artikelen",
    perm: "kanLezen"
  },
  {
    title: "Magazijnen",
    uri: "/magazijnen",
    perm: "kanLezen"
  },
  {
    title: "Lenen",
    uri: "/lenen",
    perm: "kanLenen"
  },
  {
    title: "Gebruikers",
    uri: "/gebruikers",
    admin: true
  },
  {
    title: "Beheer",
    uri: "/beheer",
    admin: true
  },
  {
    title: "FAQ",
    uri: "/faq"
  }
];
