import { toast } from "react-toastify";
import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

axios.interceptors.request.use(
  config => {
    config.headers = {
      "x-access-token": localStorage.getItem("access-token"),
      "x-refresh-token": localStorage.getItem("refresh-token")
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.headers["x-access-token"])
      localStorage.setItem("access-token", response.headers["x-access-token"]);

    if (response.headers["x-refresh-token"])
      localStorage.setItem("refresh-token", response.headers["x-refresh-token"]);
    return response;
  },
  error => {
    if (error.message === "Network Error") {
      if (!toast.isActive("NET_ERR"))
        toast.error(
          "Er is iets misgegaan tijdens de communcatie naar de server, probeer het later nog eens. Gelieve contact op te nemen met een beheerder als dit probleem zich blijft voordoen..",
          { toastId: "NET_ERR" }
        );
      return Promise.reject(error);
    }
    try {
      if (error.response.status === 403) {
        localStorage.clear();
        toast.error(
          "Dit account is geblokkeerd, neem contact op met een administrator voor meer informatie."
        );
        return <Redirect to="/login" />;
      }
      if (error.response.status === 401) {
        toast.warn("Dit account heeft onvoldoende rechten om deze actie uit te voeren.");
      }
      if (error.response.status === 500) {
        let _toastResponse =
          "Er is iets misgegaan tijdens het uitvoeren van deze actie. Als u aan het verwijderen bent, gelieve eerst te controleren of het object nergens anders gebruikt wordt, dit kan het probleem soms verhelpen. Als dit probleem zich blijft voordoen, gelieve contact op te nemen met de systeembeheerder.";
        if (error.response.data.message !== undefined) _toastResponse = error.response.data.message;
        toast.error(_toastResponse, { autoClose: 10000 });
      }
    } catch (error) {
      if (error.response.status !== 403) return Promise.reject(error);
    }
    if (error.response.status !== 403) return Promise.reject(error);
  }
);
