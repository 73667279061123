import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Col,
  Container,
  Button,
  Row
} from "react-bootstrap";
import styles from "./Profile.module.scss";
import jwt from "jsonwebtoken";
import Axios from "axios";
import { toast } from "react-toastify";

export default () => {
  const currentUser = jwt.decode(localStorage.getItem("access-token")).user;
  // -- user new data
  const [email, setEmail] = useState("");
  const [telefoonnummer, setTelefoonnummer] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [tussenvoegsel, setTussenvoegsel] = useState("");
  const [achternaam, setAchternaam] = useState("");

  const HandleSubmit = async e => {
    if (e) e.preventDefault();
    await Axios.post(process.env.REACT_APP_AUTH_API_URI + "/profile/" + currentUser.ID, {
      email: email,
      telefoonnummer: telefoonnummer,
      voornaam: voornaam,
      tussenvoegsel: tussenvoegsel,
      achternaam: achternaam
    })
      .then(res => toast.success(res.data.message))
      .catch(err => console.error(err));
  };

  const FetchUser = async () => {
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/profile/" + currentUser.ID)
      .then(res => {
        const { Email, Voornaam, Tussenvoegsel, Achternaam, Telefoonnummer } = res.data;
        setEmail(Email);
        setTelefoonnummer(Telefoonnummer);
        setVoornaam(Voornaam);
        setTussenvoegsel(Tussenvoegsel);
        setAchternaam(Achternaam);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    FetchUser();
    return () => {
      setEmail("");
      setTelefoonnummer("");
      setVoornaam("");
      setTussenvoegsel("");
      setAchternaam("");
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={[
        styles.Container,
        "d-flex justify-content-center align-items-center flex-column"
      ].join(" ")}
    >
      <Container className="bg-white shadow rounded p-4">
        <h2 className="text-center">Uw accountinformatie</h2>
        <hr />
        <Form onSubmit={HandleSubmit}>
          <Form.Row>
            <Col md={6}>
              <FormGroup>
                <FormLabel>E-mail adres</FormLabel>
                <FormControl
                  type="email"
                  required
                  placeholder="Vul hier uw e-mail adres in"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormLabel>Telefoonnummer</FormLabel>
                <FormControl
                  type="text"
                  required
                  placeholder="Vul hier uw telefoonnummer in"
                  onChange={e => setTelefoonnummer(e.target.value)}
                  value={telefoonnummer}
                />
              </FormGroup>
            </Col>
          </Form.Row>
          <hr />
          <Form.Row>
            <Col md={4}>
              <FormGroup>
                <FormLabel>Voornaam</FormLabel>
                <FormControl
                  type="text"
                  required
                  placeholder="Vul hier uw voornaam in"
                  onChange={e => setVoornaam(e.target.value)}
                  value={voornaam}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <FormLabel>Tussenvoegsel</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Vul hier uw tussenvoegsel in"
                  onChange={e => setTussenvoegsel(e.target.value)}
                  value={tussenvoegsel}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <FormLabel>Achternaam</FormLabel>
                <FormControl
                  type="text"
                  required
                  placeholder="Vul hier uw achternaam in"
                  onChange={e => setAchternaam(e.target.value)}
                  value={achternaam}
                />
              </FormGroup>
            </Col>
          </Form.Row>
          <hr />
          <Row>
            <Col xs={6}>
              <Button block variant="outline-dark" onClick={FetchUser}>
                Herstellen
              </Button>
            </Col>
            <Col xs={6}>
              <Button block variant="primary" type="submit">
                Bijwerken
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
