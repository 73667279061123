import React from "react";
import {
  Table,
  Container,
  InputGroup,
  Button,
  FormControl,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Modal
} from "react-bootstrap";
import { withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle as infoIcon,
  faPen as editIcon,
  faTrash as deleteIcon,
  faInfoCircle,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../components/auth/PermissionValidation";
import moment from "moment";
import ArtikelExcelExportModal from "./ArtikelExcelExportModal";

const Artikelen = props => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [artikelData, setArtikelenData] = React.useState([]);
  const [toArtikelToevoegen, setToArtikelToevoegen] = React.useState(false);
  const [artikelWijzigenNummer, setArtikelWijzigenNummer] = React.useState(0);
  const [toArtikelWijzigen, setToArtikelWijzigen] = React.useState(false);
  const [artikelToDelete, setArtikelToDelete] = React.useState(0);
  const [showLeverancierModal, setShowLeverancierModal] = React.useState(false);
  const [leverancierArtikelnummer, setLeverancierArtikelnummer] = React.useState("");
  const [leverancierData, setLeverancierData] = React.useState([]);
  const [modalFetching, setModalFetching] = React.useState(false);
  const [artikelFilter, setArtikelFilter] = React.useState("");
  const [toDefectOverzicht, setToDefectOverzicht] = React.useState(false);

  const GetArtikelData = () => {
    setIsFetching(true);
    Axios.get(process.env.REACT_APP_AUTH_API_URI + "/artikelen", {
      timeout: 3000
    })
      .then(response => {
        setIsFetching(false);
        if (response.status === 200)
          setArtikelenData(
            response.data.map(a => {
              a.EigenaarVolledig = `${a.Afkorting} ${a.Plaats} (${a.Straat} ${a.Huisnummer})`;
              return a;
            })
          );
      })
      .catch(error => {
        setIsFetching(false);
      });
  };

  React.useEffect(() => {
    GetArtikelData();
  }, []);

  const DeleteArtikel = async () => {
    setIsDeleting(true);
    await Axios.delete(process.env.REACT_APP_AUTH_API_URI + "/artikelen/" + artikelToDelete, {
      timeout: 3000
    })
      .then(response => {
        GetArtikelData();
      })
      .catch(error => {
        GetArtikelData();
      });
    setIsDeleting(false);
    setShowDeleteModal(false);
    setArtikelToDelete(0);
  };

  const WijzigArtikel = nummer => {
    setArtikelWijzigenNummer(nummer);
    setToArtikelWijzigen(true);
  };

  const GetLeverancierInfo = async artikel => {
    setLeverancierArtikelnummer(artikel.Artikelnummer);
    setShowLeverancierModal(true);
    setModalFetching(true);
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/leveranciers/" + artikel.Artikelnummer, {
      timeout: 2000
    })
      .then(response => {
        setLeverancierData(response.data);
        setModalFetching(false);
      })
      .catch(error => {
        console.error(error);
        setModalFetching(false);
      });
  };

  return (
    <Container className="p-5" fluid>
      {toArtikelToevoegen ? <Redirect to="/artikelen/toevoegen" /> : null}
      {toArtikelWijzigen ? <Redirect to={"/artikelen/wijzigen/" + artikelWijzigenNummer} /> : null}
      {toDefectOverzicht ? <Redirect to="/artikelen/defecten" /> : null}
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Artikelen</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={3}>
                {PermissionValidation(
                  "kanToevoegen",
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => setToArtikelToevoegen(true)}
                  >
                    Nieuw artikel
                  </Button>
                )}
              </Col>
              <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={3}>
                <ArtikelExcelExportModal artikelen={artikelData} />
              </Col>
              <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={3}>
                {PermissionValidation(
                  "kanWijzigen",
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => setToDefectOverzicht(true)}
                  >
                    Artikel defecten
                  </Button>
                )}
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Artikelen zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => setArtikelFilter(e.target.value)}
            />
          </InputGroup>
          <Col className="p-0">
            {isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="barcode-tooltip">
                            Dit kan een barcode of een artikelnummer zijn.
                          </Tooltip>
                        }
                      >
                        <div>
                          Artikelnr
                          <FontAwesomeIcon icon={infoIcon} className="ml-2 text-muted" />
                        </div>
                      </OverlayTrigger>
                    </th>
                    <th>Omschrijving</th>
                    <th>Activiteit</th>
                    <th>Groep</th>
                    <th>Merk</th>
                    <th>Eigenaar artikel</th>
                    <th>Magazijn</th>
                    <th>Locatie</th>
                    <th>Aantal</th>
                    <th>Leverancier</th>
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {artikelData
                    .filter(a => {
                      a.filterThrough = false;
                      if (artikelFilter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in a) {
                          if (a[index] !== null && a.filterThrough === false) {
                            a.filterThrough = a[index]
                              .toString()
                              .toLowerCase()
                              .includes(artikelFilter.toString().toLowerCase());
                          }
                        }
                        return a.filterThrough;
                      } else return true;
                    })
                    .map((artikel, i) => {
                      return (
                        <tr key={i}>
                          <td>{artikel.Artikelnummer}</td>
                          <td>{artikel.Omschrijving}</td>
                          <td>{artikel.Activiteiten}</td>
                          <td>{artikel.Groep}</td>
                          <td>{artikel.Merknaam}</td>
                          <td>{artikel.EigenaarVolledig}</td>
                          <td>{artikel.Magazijn}</td>
                          <td>{artikel.Locaties}</td>
                          <td>{artikel.Aantal}</td>
                          <td>
                            <Button
                              size="sm"
                              variant="secondary"
                              block
                              onClick={() => GetLeverancierInfo(artikel)}
                            >
                              INFO <FontAwesomeIcon icon={faInfoCircle} />
                            </Button>
                          </td>

                          <td>
                            {PermissionValidation(
                              "kanWijzigen",
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={() => WijzigArtikel(artikel.Artikelnummer)}
                              >
                                <FontAwesomeIcon icon={editIcon} />
                              </Button>
                            )}
                            &nbsp;
                            {PermissionValidation(
                              "kanVerwijderen",
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  setArtikelToDelete(artikel.Artikelnummer);
                                  setShowDeleteModal(true);
                                }}
                              >
                                <FontAwesomeIcon icon={deleteIcon} />
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
      </Container>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDeleteModal(false)}
        show={showDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Artikel verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Weet u zeker dat u dit artikel wilt verwijderen? Als u dit artikel verwijderd dan wordt
            dit artikel uit het hele systeem verwijderd, dus ook de voorraad en dergelijke.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeleteModal(false)} variant="dark">
            Annuleren
          </Button>
          <Button onClick={DeleteArtikel} variant="danger">
            Verwijderen
            {isDeleting ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        aria-labelledby="leverancier-modal"
        onHide={() => setShowLeverancierModal(false)}
        show={showLeverancierModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="leverancier-modal">
            Leveranciers ({leverancierArtikelnummer})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalFetching ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {leverancierData.map((leverancier, index) => {
                return (
                  <div key={index} className="mb-3">
                    <h5>Leverancier {index + 1}</h5>
                    <Table bordered size="sm" className="bg-white m-0 font-weight-normal">
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">Leveranciersnaam</td>
                          <td>{leverancier.Leverancier}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                );
              })}
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default withRouter(Artikelen);
