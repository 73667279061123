import React, { useState, useEffect } from "react";
import moment from "moment";
import Axios from "axios";
import {
  Modal,
  Form,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Spinner
} from "react-bootstrap";
import styles from "./Innemen.module.scss";
import {
  faStopwatch as NowIcon,
  faAngleDoubleUp as MaxIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const API = process.env.REACT_APP_AUTH_API_URI;
export default ({ ID, closeModal, showModal, complete, uitgiften }) => {
  const [datum, setDatum] = useState(moment().format("YYYY-MM-DD"));
  const [tijd, setTijd] = useState(moment().format("HH:mm"));
  const [opmerkingen, setOpmerkingen] = useState("");
  const [updating, setUpdating] = useState(false);
  // -- uitgifte data
  const [uitgifteItemLijst, setUitgifteItemLijst] = useState([]);
  const [artikelNummersBijAantal, setArtikelNummersBijAantal] = useState({});

  const SetAantal = (anr, aantal) => {
    // aantal = aantal !== "" ? parseInt(aantal) : 0;
    // obj set
    let _obj = { ...artikelNummersBijAantal };
    _obj[anr] = aantal;
    setArtikelNummersBijAantal(_obj);
    // list set
    let _list = [...uitgifteItemLijst];
    _list = _list.map(item => {
      item.HuidigAantal = item.Artikelnummer === anr ? aantal : item.HuidigAantal;
      return item;
    });
    setUitgifteItemLijst(_list);
  };

  const HandleAantalBlur = (anr, max, aantal) => {
    aantal = aantal !== "" ? parseInt(aantal) : 0;
    let aantalReturn = aantal;
    if (aantal > max) aantalReturn = max;
    else if (aantal < 0) aantalReturn = 0;
    SetAantal(anr, aantalReturn);
  };

  const GetSetHuidigeUitgifte = () => {
    const _list = [...uitgiften].map(uitgifte => {
      uitgifte.Items.map(item => {
        item.HuidigAantal = item.MaxAantal;
        return item;
      });
      return uitgifte;
    });
    const index = _list.findIndex(u => u.ID === ID);
    const uitgifte = index !== -1 ? { ..._list[index] } : {};
    GetSetUitgifteItemLijst(uitgifte);
    GetSetArtikelNummersBijAantal(uitgifte);
  };

  const GetSetUitgifteItemLijst = uitgifte => setUitgifteItemLijst(uitgifte.Items);

  const GetSetArtikelNummersBijAantal = ({ Items }) => {
    let obj = {};
    Items.forEach(item => {
      obj[item.Artikelnummer] = parseInt(item.MaxAantal);
    });
    setArtikelNummersBijAantal(obj);
  };

  useEffect(() => {
    if (uitgiften.length > 0 && ID !== null) {
      GetSetHuidigeUitgifte();
    }
    return () => {
      setUitgifteItemLijst([]);
      setArtikelNummersBijAantal({});
    };
    // eslint-disable-next-line
  }, [uitgiften, ID]);

  const HandleSubmit = e => {
    if (e) e.preventDefault();
    const convertDate = () => {
      const _d = datum;
      const _t = tijd + ":00";
      const _date = new Date(_d.toString() + ":" + _t.toString());
      return moment(_date).format("YYYY-MM-DD HH:mm:ss");
    };
    const _body = {
      Datuminname: convertDate(),
      ID: ID,
      Opmerkingen: opmerkingen,
      Items: uitgifteItemLijst
    };
    setUpdating(true);
    Axios.post(API + "/lenen/innemen", _body)
      .then(res => {
        complete();
        closeModal();
      })
      .catch(err => console.error(err));
    setUpdating(false);
  };

  const ResetTime = () => {
    setDatum(moment().format("YYYY-MM-DD"));
    setTijd(moment().format("HH:mm"));
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter-add"
      centered
      onHide={closeModal}
      show={showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter-add">Uitgifte innemen</Modal.Title>
      </Modal.Header>
      <Form onSubmit={HandleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Col md={5}>
              <FormGroup>
                <FormLabel>Datum inname</FormLabel>
                <FormControl
                  type="date"
                  placeholder="Vul hier datum van inname in"
                  onChange={e => setDatum(e.target.value)}
                  value={datum}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <FormLabel>Tijd inname</FormLabel>
                <FormControl
                  type="time"
                  placeholder="Vul hier de tijd van inname in"
                  onChange={e => setTijd(e.target.value)}
                  value={tijd}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormLabel>&nbsp;</FormLabel>
              <Button variant="secondary" block onClick={ResetTime}>
                <FontAwesomeIcon icon={NowIcon} className="mr-2 mr-md-1" />
                NU
              </Button>
            </Col>
          </Form.Row>
          <Form.Row className="my-3">
            <Col xs={12}>
              <p className="font-weight-bold m-0">Ingenomen artikelen</p>
            </Col>
            <Col xs={12}>
              {uitgifteItemLijst.map(item => {
                return (
                  <Col
                    xs={12}
                    className={styles.InnemenArtikel}
                    key={`${item.Artikelnummer}-${item.Aantal}`}
                  >
                    <Button
                      size="sm"
                      className={styles.InnemenArtikelButton}
                      onClick={() => SetAantal(item.Artikelnummer, item.MaxAantal)}
                    >
                      <FontAwesomeIcon icon={MaxIcon} />
                    </Button>
                    <FormControl
                      size="sm"
                      type="number"
                      className={styles.InnemenArtikelControl}
                      value={artikelNummersBijAantal[item.Artikelnummer]}
                      min="0"
                      max={item.MaxAantal}
                      onChange={({ target }) => SetAantal(item.Artikelnummer, target.value)}
                      onBlur={({ target }) =>
                        HandleAantalBlur(item.Artikelnummer, item.MaxAantal, target.value)
                      }
                    />
                    <div className={styles.InnemenArtikelInfo}>
                      {item.Artikelnummer}
                      <i>{item.Bijzonderheden ? ` (${item.Bijzonderheden})` : ""}</i>
                    </div>
                  </Col>
                );
              })}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12}>
              <FormGroup>
                <FormLabel>Opmerkingen</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Vul hier de opmerkingen in"
                  onChange={e => setOpmerkingen(e.target.value)}
                  value={opmerkingen}
                />
              </FormGroup>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="dark">
            Annuleren
          </Button>
          <Button type="submit" variant="primary">
            Innemen
            {updating ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
