import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner,
  FormText
} from "react-bootstrap";
import Axios from "axios";
import ArtikelExcelImport from "./ArtikelExcelImport";
import ArtikelToevoegenLeveranciers from "./ArtikelToevoegenLeveranciers";

const API = process.env.REACT_APP_AUTH_API_URI;
class ArtikelToevoegen extends Component {
  state = {
    leavePage: false,
    isFetching: false,
    importing: false,
    data: {
      activiteiten: [],
      groepen: [],
      merken: [],
      eigenaren: [],
      magazijnen: [],
      leveranciers: []
    },
    artikelnummer: "",
    omschrijving: "",
    merkid: 0,
    eigenaarid: 0,
    activiteitid: 0,
    groepid: 0,
    magazijnid: 0,
    locatie: "",
    aantal: 0,
    leveranciers: []
  };

  async componentDidMount() {
    this.setState({ isFetching: true });
    await Promise.all([
      Axios.get(API + "/activiteiten"),
      Axios.get(API + "/groepen"),
      Axios.get(API + "/merken"),
      Axios.get(API + "/eigenaren"),
      Axios.get(API + "/magazijnen"),
      Axios.get(API + "/leveranciers")
    ]).then(res => {
      this.setState({
        data: {
          activiteiten: res[0].data,
          groepen: res[1].data,
          merken: res[2].data,
          eigenaren: res[3].data,
          magazijnen: res[4].data,
          leveranciers: res[5].data
        },
        merkid: res[2].data[0] ? res[2].data[0].ID : 0,
        eigenaarid: res[3].data[0] ? res[3].data[0].ID : 0,
        activiteitid: res[0].data[0] ? res[0].data[0].ID : 0,
        groepid: res[1].data[0] ? res[1].data[0].ID : 0,
        magazijnid: res[5].data[0] ? res[5].data[0].ID : 0,
        leveranciers: [],
        isFetching: false
      });
    });
  }

  SetLeveranciers = leveranciers => this.setState({ leveranciers: leveranciers });


  GetMerkId = e =>
    this.setState({
      merkid: [...this.state.data.merken][e.target.selectedIndex].ID
    });

  GetEigenaarId = e =>
    this.setState({
      eigenaarid: [...this.state.data.eigenaren][e.target.selectedIndex].ID
    });

  GetActiviteitId = e =>
    this.setState({
      activiteitid: [...this.state.data.activiteiten][e.target.selectedIndex].ID
    });

  GetGroepId = e =>
    this.setState({
      groepid: [...this.state.data.groepen][e.target.selectedIndex].ID
    });

  GetMagazijnId = e =>
    this.setState({
      magazijnid: [...this.state.data.magazijnen][e.target.selectedIndex].ID
    });

  HandleSubmit = event => {
    if (event) event.preventDefault();

    Axios.post(API + "/artikelen", {
      artikelnummer: this.state.artikelnummer,
      omschrijving: this.state.omschrijving,
      merkid: this.state.merkid,
      eigenaarid: this.state.eigenaarid,
      activiteitid: this.state.activiteitid,
      groepid: this.state.groepid,
      magazijnid: this.state.magazijnid,
      locatie: this.state.locatie,
      aantal: this.state.aantal,
      leveranciers: this.state.leveranciers
    })
      .then(response => this.setState({ toegevoegd: true, leavePage: true }))
      .catch(error => console.error(error));
  };

  toggleExcel = () => this.setState({ importing: !this.state.importing });

  render() {
    return (
      <Container className="p-5">
        {this.state.leavePage ? <Redirect to="/artikelen" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">
                  {this.state.importing ? "Artikelen importeren" : "Artikel toevoegen"}
                </h1>
              </Col>
              <Col md={6}>
                <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={5}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ leavePage: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
                <Col className="p-0 float-right" md={10} lg={6} xl={5}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={this.toggleExcel}
                  >
                    {this.state.importing ? "Handmatig" : "Importeren"}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              {this.state.importing ? (
                <ArtikelExcelImport leavePage={() => this.setState({ leavePage: true })} />
              ) : this.state.isFetching ? (
                <div className="text-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <Form onSubmit={this.HandleSubmit}>
                  <Form.Row>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Artikelnummer</FormLabel>
                        <FormControl
                          type="text"
                          onChange={e => this.setState({ artikelnummer: e.target.value })}
                          placeholder="Vul hier het arikelnummer in"
                          required
                        />
                        <FormText>Een artikelnummer kan ook een barcode zijn</FormText>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormLabel>Omschrijving</FormLabel>
                      <FormControl
                        type="text"
                        onChange={e => this.setState({ omschrijving: e.target.value })}
                        placeholder="Vul hier de omschrijving in"
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Merk</FormLabel>
                        <FormControl as="select" onChange={this.GetMerkId}>
                          {this.state.data.merken.map(merk => {
                            return <option key={merk.ID}>{merk.Merknaam}</option>;
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Eigenaar</FormLabel>
                        <FormControl as="select" onChange={this.GetEigenaarId}>
                          {this.state.data.eigenaren.map(eigenaar => {
                            return (
                              <option key={eigenaar.ID}>
                                {eigenaar.Afkorting} - {eigenaar.Plaats} ({eigenaar.Straat}{" "}
                                {eigenaar.Huisnummer})
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Activiteit</FormLabel>
                        <FormControl as="select" onChange={this.GetActiviteitId}>
                          {this.state.data.activiteiten.map(activiteit => {
                            return <option key={activiteit.ID}>{activiteit.Activiteit}</option>;
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Groep</FormLabel>
                        <FormControl as="select" onChange={this.GetGroepId}>
                          {this.state.data.groepen.map(groep => {
                            return <option key={groep.ID}>{groep.Artikelgroep}</option>;
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Magazijn</FormLabel>
                        <FormControl as="select" onChange={this.GetMagazijnId}>
                          {this.state.data.magazijnen.map(magazijn => {
                            return <option key={magazijn.ID}>{magazijn.Magazijn}</option>;
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Locatie</FormLabel>
                        <FormControl
                          type="text"
                          onChange={e =>
                            this.setState({
                              locatie: e.target.value.toString().toLowerCase()
                            })
                          }
                          placeholder="Vul hier de locatie in"
                          required
                        />
                        <FormText>Dit is de locatie binnen het magazijn</FormText>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <FormLabel>Aantal</FormLabel>
                        <FormControl
                          type="number"
                          min={0}
                          defaultValue={this.state.aantal}
                          required
                          onChange={e => this.setState({ aantal: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <ArtikelToevoegenLeveranciers
                      data={this.state.data.leveranciers}
                      setLeveranciers={this.SetLeveranciers}
                    />
                  </Form.Row>
                  <hr />
                  <Button type="submit" variant="secondary" block className="mt-3">
                    Voeg artikel toe
                  </Button>
                </Form>
              )}
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default ArtikelToevoegen;
