import React, { Component } from "react";
import ReactExport from "react-data-export";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Axios from "axios";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const API = process.env.REACT_APP_AUTH_API_URI;
const ConvertButton = ({ func, disabled }) => {
  return (
    <Button
      className="float-right"
      variant="dark"
      block
      onClick={func ? func : null}
      disabled={disabled}
    >
      Download invullijst
      {disabled ? <Spinner variant="white" animation="border" size="sm" className="ml-1" /> : null}
    </Button>
  );
};

export default class AEIL extends Component {
  state = {
    artikelExcelData: [],
    merkenExcelData: [],
    eigenarenExcelData: [],
    activiteitenExcelData: [],
    groepenExcelData: [],
    magazijnenExcelData: [],
    leverancierExcelData: [],
    download: false,
    hasFetched: false
  };

  // -- kolommen die langer moeten zijn
  longCols = [
    "Artikelnummer",
    "Omschrijving",
    "Straat",
    "Contactpersoon",
    "Eigenaar straat",
    "Opmerkingen",
    "E-mail"
  ];

  // -- kolommen voor de invullijst
  invullijstColumns = [
    "Artikelnummer",
    "Omschrijving",
    "Merk nr.",
    "Eigenaar nr.",
    "Activiteit nr.",
    "Groep nr.",
    "Magazijn nr.",
    "Locatie",
    "Aantal",
    "Leverancier"
  ];

  GetInvullijstData = () => {
    let data = [];
    let obj = {};
    this.invullijstColumns.forEach(c => {
      obj[c.toString()] = "";
    });
    data.push(obj);
    return data;
  };

  ConvertDataToExcel = (cols, data) => {
    let _cols = [];
    let _rows = [];
    data.forEach(d => {
      let item = [];
      // eslint-disable-next-line
      for (let key of Object.keys(d))
        item.push({
          value: d[key] === undefined || d[key] === null ? "" : d[key].toString()
        });
      _rows.push(item);
    });
    cols.forEach(c => {
      let item = {};
      item.value = c.toString();
      item.style = {
        alignment: { horizontal: "center" },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" }
        },
        font: { bold: true, color: { rgb: "FFFFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "FFE5005B" } }
      };
      _cols.push(item);
    });
    _rows.unshift(_cols);
    const cTopColumns = _cols.map(({ value }) => {
      return { title: "", width: { wch: this.longCols.includes(value.toString()) ? 30 : 20 } };
    });

    return [{ columns: cTopColumns, data: _rows }];
  };

  FetchAllData = async () => {
    await Promise.all([
      Axios.get(API + "/merken"),
      Axios.get(API + "/eigenaren"),
      Axios.get(API + "/activiteiten"),
      Axios.get(API + "/groepen"),
      Axios.get(API + "/magazijnen"),
      Axios.get(API + "/leveranciers")
    ]).then(res => {
      this.setState({
        artikelExcelData: this.ConvertDataToExcel(this.invullijstColumns, this.GetInvullijstData()),
        merkenExcelData: this.ConvertDataToExcel(["Nummer", "Merknaam"], res[0].data),
        eigenarenExcelData: this.ConvertDataToExcel(
          ["Nummer", "Afkorting", "Straat", "Huisnummer", "Postcode", "Plaats"],
          res[1].data.map(e => {
            delete e.Volledig;
            return e;
          })
        ),
        activiteitenExcelData: this.ConvertDataToExcel(["Nummer", "Activiteit"], res[2].data),
        groepenExcelData: this.ConvertDataToExcel(["Nummer", "Groep"], res[3].data),
        magazijnenExcelData: this.ConvertDataToExcel(
          [
            "Nummer",
            "Magazijn",
            "Opmerkingen",
            "Eigenaar afk.",
            "Eigenaar plaats",
            "Eigenaar straat",
            "Eigenaar huisnr."
          ],
          res[4].data.map(m => {
            delete m.Eigenaar_id;
            return m;
          })
        ),
        leverancierExcelData: this.ConvertDataToExcel(
          [
            "Nummer",
            "Leverancier",
          ],
          res[5].data
        ),
        download: true,
        hasFetched: true
      });
    });
  };

  componentDidMount() {
    this.FetchAllData();
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={8} className="d-flex justify-content-start align-items-center">
          <p className="m-0 font-weight-bold">Dit systeem is afhankelijk van de invullijst..</p>
        </Col>
        <Col xs={12} md={4}>
          {this.state.hasFetched ? (
            <ExcelFile
              filename={`ArtikelInvullijst-${moment().format("DD-MM-YYYY")}`}
              element={<ConvertButton />}
            >
              <ExcelSheet dataSet={this.state.artikelExcelData} name="Invullijst" />
              <ExcelSheet dataSet={this.state.merkenExcelData} name="Merken data" />
              <ExcelSheet dataSet={this.state.eigenarenExcelData} name="Eigenaren data" />
              <ExcelSheet dataSet={this.state.activiteitenExcelData} name="Activiteiten data" />
              <ExcelSheet dataSet={this.state.groepenExcelData} name="Groepen data" />
              <ExcelSheet dataSet={this.state.magazijnenExcelData} name="Magazijnen data" />
              <ExcelSheet dataSet={this.state.leverancierExcelData} name="Leveranciers data" />
            </ExcelFile>
          ) : (
            <ConvertButton disabled={!this.state.hasFetched} />
          )}
        </Col>
      </Row>
    );
  }
}
