import React from "react";
import {
  Table,
  Container,
  InputGroup,
  Button,
  FormControl,
  Col,
  Row,
  Spinner,
  Modal
} from "react-bootstrap";
import { withRouter, Redirect } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as editIcon,
  faTrash as deleteIcon,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../components/auth/PermissionValidation";

const Gebruikers = props => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [gebruikerData, setGebruikerData] = React.useState([]);
  const [toGebruikerToevoegen, setToGebruikerToevoegen] = React.useState(false);
  const [toGebruikerWijzigen, setToGebruikerWijzigen] = React.useState(false);
  const [gebruikerToDelete, setGebruikerToDelete] = React.useState(0);
  const [gebruikerToEdit, setGebruikerToEdit] = React.useState(0);
  const [filter, setFilter] = React.useState("");

  const GetGebruikerData = () => {
    setIsFetching(true);
    Axios.get(process.env.REACT_APP_AUTH_API_URI + "/gebruikers", {
      timeout: 3000
    })
      .then(response => {
        setIsFetching(false);
        if (response.status === 200) setGebruikerData(response.data);
      })
      .catch(error => {
        setIsFetching(false);
      });
  };

  React.useEffect(() => {
    GetGebruikerData();
    return () => {};
  }, []);

  const DeleteGebruiker = async () => {
    setIsDeleting(true);
    await Axios.delete(process.env.REACT_APP_AUTH_API_URI + "/gebruikers/" + gebruikerToDelete, {
      timeout: 3000
    })
      .then(response => {
        GetGebruikerData();
      })
      .catch(error => {
        GetGebruikerData();
      });
    setIsDeleting(false);
    setShowDeleteModal(false);
    setGebruikerToDelete(0);
  };

  const EditUser = id => {
    setGebruikerToEdit(id);
    setToGebruikerWijzigen(true);
  };

  return (
    <Container className="p-5" fluid>
      {toGebruikerToevoegen ? <Redirect to="/gebruikers/toevoegen" /> : null}
      {toGebruikerWijzigen ? <Redirect to={"/gebruikers/wijzigen/" + gebruikerToEdit} /> : null}
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Gebruikers</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right" md={10} lg={6}>
                <Button
                  className="float-right"
                  variant="outline-light"
                  block
                  onClick={() => setToGebruikerToevoegen(true)}
                >
                  Nieuwe gebruiker toevoegen
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Gebruikers zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => setFilter(e.target.value)}
            />
          </InputGroup>
          <Col className="p-0">
            {isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Voornaam</th>
                    <th>Tussenvoegsel</th>
                    <th>Achternaam</th>
                    <th>Telefoonnummer</th>
                    <th>Actief</th>
                    <th>Geblokkeerd</th>
                    <th>Rechten</th>
                    {PermissionValidation("kanWijzigen" || "kanVerwijderen", <th>Acties</th>)}
                  </tr>
                </thead>
                <tbody>
                  {gebruikerData
                    .filter(g => {
                      g.filterThrough = false;
                      if (filter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in g) {
                          if (g[index] !== null && g.filterThrough === false) {
                            g.filterThrough = g[index]
                              .toString()
                              .toLowerCase()
                              .includes(filter.toString().toLowerCase());
                          }
                        }
                        return g.filterThrough;
                      } else return true;
                    })
                    .map((gebruiker, i) => {
                      return (
                        <tr key={i}>
                          <td>{gebruiker.ID}</td>
                          <td>{gebruiker.Email}</td>
                          <td>{gebruiker.Voornaam}</td>
                          <td>{gebruiker.Tussenvoegsel}</td>
                          <td>{gebruiker.Achternaam}</td>
                          <td>{gebruiker.Telefoonnummer}</td>
                          <td>{gebruiker.Actief ? "Ja" : "Nee"}</td>
                          <td>{gebruiker.Geblokkeerd ? "Ja" : "Nee"}</td>
                          <td>{gebruiker.Rechten}</td>
                          {PermissionValidation(
                            "kanWijzigen" || "kanVerwijderen",
                            <td>
                              {PermissionValidation(
                                "kanWijzigen",
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() => {
                                    EditUser(gebruiker.ID);
                                  }}
                                >
                                  <FontAwesomeIcon icon={editIcon} />
                                </Button>
                              )}
                              &nbsp;
                              {PermissionValidation(
                                "kanVerwijderen",
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    setGebruikerToDelete(gebruiker.ID);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={deleteIcon} />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
      </Container>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDeleteModal(false)}
        show={showDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Gebruiker verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Weet u zeker dat u deze gebruiker wilt verwijderen? Deze actie kan niet ongedaan gemaakt
            worden.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeleteModal(false)} variant="dark">
            Annuleren
          </Button>
          <Button onClick={DeleteGebruiker} variant="danger">
            Verwijderen
            {isDeleting ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default withRouter(Gebruikers);
