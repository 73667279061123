import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Form,
  FormGroup,
  FormLabel,
  FormControl
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt as UitleenIcon,
  faSignInAlt as InneemIcon,
  faCalendarAlt as FilterIcon,
  faTimes,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import OverzichtItem from "./OverzichtItem";
import moment from "moment";

export default props => {
  const [exitPage, setExitPage] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [openstaandeUitgiften, setOpenstaandeUitgiften] = useState([]);
  const [afgerondeUitgiften, setAfgerondeUitgiften] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getUitgiften = async () => {
    setHasFetched(false);
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/lenen/overzicht")
      .then(res => {
        setOpenstaandeUitgiften(res.data.openstaand);
        setAfgerondeUitgiften(res.data.afgerond);
      })
      .catch(err => console.error(err));
    setHasFetched(true);
  };

  useEffect(() => {
    getUitgiften();
  }, []);

  const clearDates = () => {
    setStartDate("");
    setEndDate("");
  };

  const applyFilter = () => {
    let _openstaand = [...openstaandeUitgiften];
    let _afgerond = [...afgerondeUitgiften];
    // "from" = from start date
    // "to" = to end date
    // "between" = between two dates;
    let type = null;

    if (startDate !== "" && endDate !== "") type = "between";
    else if (startDate !== "" && endDate === "") type = "from";
    else if (startDate === "" && endDate !== "") type = "to";

    const shouldBeFiltered = obj => {
      let { Datumuitgifte, Datuminname } = obj;
      switch (type) {
        case "from":
          return (
            moment(Datuminname).isSameOrAfter(startDate) ||
            moment(Datumuitgifte).isSameOrAfter(startDate)
          );
        case "to":
          return (
            moment(Datuminname).isSameOrBefore(endDate) ||
            moment(Datumuitgifte).isSameOrBefore(endDate)
          );
        case "between":
          return (
            moment(Datuminname).isBetween(startDate, endDate) ||
            moment(Datumuitgifte).isBetween(startDate, endDate)
          );
        default:
          return false;
      }
    };

    if (startDate === "" && endDate === "") {
      setHasFetched(false);
      getUitgiften();
    } else {
      _openstaand = _openstaand.filter(o => shouldBeFiltered(o));
      _afgerond = _afgerond.filter(a => shouldBeFiltered(a));
      setOpenstaandeUitgiften(_openstaand);
      setAfgerondeUitgiften(_afgerond);
    }
  };

  return (
    <Container className="p-5" fluid>
      {exitPage ? <Redirect to="/lenen" /> : null}
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6} sm={7}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Overzicht </h1>
            </Col>
            <Col md={6} sm={5}>
              <Col className="p-0 float-right" md={10} lg={6}>
                <Button
                  className="float-right"
                  variant="outline-light"
                  block
                  onClick={() => setExitPage(true)}
                >
                  Annuleren
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <div className="w-100 bg-white shadow-sm p-3 rounded mb-1">
            <h4 className="m-0 p-0">
              <FontAwesomeIcon icon={FilterIcon} style={{ width: 40 }} className="text-muted" />
              Filteren
            </h4>
          </div>
          <Col xs={12} className="rounded bg-white font-weight-bold shadow-sm my-2 py-2 ">
            <Form>
              <Form.Row>
                <Col xs={12} sm={4} md={5}>
                  <FormGroup>
                    <FormLabel>Startdatum</FormLabel>
                    <FormControl
                      type="date"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={5}>
                  <FormGroup>
                    <FormLabel>Einddatum</FormLabel>
                    <FormControl
                      type="date"
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} sm={2} md={1}>
                  <FormLabel>&nbsp;</FormLabel>
                  <Button block variant="danger" onClick={clearDates}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Col>
                <Col xs={6} sm={2} md={1}>
                  <FormLabel>&nbsp;</FormLabel>
                  <Button block variant="success" onClick={applyFilter}>
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
          <hr />
          <div className="w-100 bg-white shadow-sm p-3 rounded mb-1">
            <h4 className="m-0 p-0">
              <FontAwesomeIcon icon={UitleenIcon} style={{ width: 40 }} className="text-muted" />
              Openstaand
            </h4>
          </div>
          {hasFetched ? (
            openstaandeUitgiften.length > 0 ? (
              openstaandeUitgiften.map(ou => {
                return (
                  <OverzichtItem
                    key={ou.ID}
                    uitgifte={ou}
                    variant="open"
                    onComplete={getUitgiften}
                  />
                );
              })
            ) : (
              <p className="my-3 text-center text-muted">Er zijn geen openstaande items..</p>
            )
          ) : (
            <div className="text-center my-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <hr />
          <div className="w-100 bg-white shadow-sm p-3 rounded mb-1">
            <h4 className="m-0 p-0">
              <FontAwesomeIcon icon={InneemIcon} style={{ width: 40 }} className="text-muted" />
              Afgerond
            </h4>
          </div>
          {hasFetched ? (
            afgerondeUitgiften.length > 0 ? (
              afgerondeUitgiften.map(au => {
                return <OverzichtItem key={au.ID} uitgifte={au} variant="complete" />;
              })
            ) : null
          ) : (
            <div className="text-center my-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Container>
      </Container>
    </Container>
  );
};
