import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner
} from "react-bootstrap";
import Axios from "axios";

const API = process.env.REACT_APP_AUTH_API_URI;
class MagazijnWijzigen extends Component {
  state = {
    leavePage: false,
    isFetching: false,
    data: {
      eigenaren: []
    },
    eigenaarid: 0,
    magazijn: "",
    opmerkingen: ""
  };

  async componentDidMount() {
    this.setState({ isFetching: true });
    await Promise.all([
      Axios.get(API + "/eigenaren"),
      Axios.get(API + "/magazijnen/" + this.props.match.params.id)
    ]).then(res => {
      const _magazijn = res[1].data[0];
      this.setState({
        data: {
          eigenaren: res[0].data
        },
        eigenaarid: _magazijn.Eigenaar_id,
        magazijn: _magazijn.Magazijn,
        opmerkingen: _magazijn.Opmerkingen,
        isFetching: false
      });
    });
  }

  GetEigenaarId = e =>
    this.setState({
      eigenaarid: [...this.state.data.eigenaren][e.target.selectedIndex].ID
    });

  HandleSubmit = event => {
    if (event) event.preventDefault();

    Axios.put(process.env.REACT_APP_AUTH_API_URI + "/magazijnen/" + this.props.match.params.id, {
      magazijn: this.state.magazijn,
      eigenaar: this.state.eigenaarid,
      opmerkingen: this.state.opmerkingen
    })
      .then(response => this.setState({ toegevoegd: true, leavePage: true }))
      .catch(error => console.error(error));
  };

  render() {
    return (
      <Container className="p-5">
        {this.state.leavePage ? <Redirect to="/magazijnen" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Magazijn wijzigen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ leavePage: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              {this.state.isFetching ? (
                <div className="text-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <Form onSubmit={this.HandleSubmit}>
                  <Form.Row>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Magazijn</FormLabel>
                        <FormControl
                          type="text"
                          placeholder="Vul hier het magazijn in"
                          required
                          onChange={e => this.setState({ magazijn: e.target.value })}
                          defaultValue={this.state.magazijn}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Eigenaar</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetEigenaarId}
                          defaultValue={this.state.eigenaarid}
                        >
                          {this.state.data.eigenaren.map(eigenaar => {
                            return (
                              <option key={eigenaar.ID} value={eigenaar.ID}>
                                {eigenaar.Afkorting} - {eigenaar.Plaats} ({eigenaar.Straat}{" "}
                                {eigenaar.Huisnummer})
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <FormLabel>Opmerkingen</FormLabel>
                        <FormControl
                          type="text"
                          placeholder="Vul hier de opmerkingen in"
                          required
                          onChange={e => this.setState({ opmerkingen: e.target.value })}
                          defaultValue={this.state.opmerkingen}
                        />
                      </FormGroup>
                    </Col>
                  </Form.Row>
                  <Button type="submit" variant="secondary" block>
                    Wijzig magazijn
                  </Button>
                </Form>
              )}
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default MagazijnWijzigen;
