import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import AEEE from "./ArtikelExcelExportExcel";
import AEET from "./ArtikelExcelExportTellijst";

export default ({ artikelen }) => {
  const [showModal, setShowModal] = useState(false);

  const ExcelData = [...artikelen].slice();
  const TellijstData = [...artikelen].slice();

  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <Button className="float-right" variant="outline-light" block onClick={toggle}>
        Excel export
      </Button>
      <Modal show={showModal} onHide={toggle} centered>
        <Modal.Header closeButton>
          <Modal.Title>Excel export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Wat wilt u exporteren?
          <hr />
          <Row className="px-3">
            <Col className="p-2" md={6}>
              <AEEE data={ExcelData} />
            </Col>
            <Col className="p-2" md={6}>
              <AEET data={TellijstData} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
