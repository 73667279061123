import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Table,
  Modal,
  Spinner
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

const ItemSelectModal = props => {
  let artikelenLijst = [...props.artikelen];

  const [aantal, setAantal] = React.useState(0);
  const [artikelNr, setArtikelNr] = React.useState("");
  const [bijzonderheden, setBijzonderheden] = React.useState("");
  const [artikelAantal, setArtikelAantal] = React.useState(0);
  const [disableFields, setDisableFields] = React.useState(true);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (artikelenLijst.length > 0 && artikelNr === "") {
      setArtikelNr(artikelenLijst[0].Artikelnummer);
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    const _artikelToAdd = {
      aantal: aantal,
      artikelnr: artikelNr,
      bijzonderheden: bijzonderheden
    };
    props.addArtikel(_artikelToAdd);
    props.toggle();
    setAantal(0);
    setArtikelNr("");
    setBijzonderheden("");
  };

  const getArtikelnummer = e => {
    const artikel = [...artikelenLijst][e.target.selectedIndex];
    if (
      artikel.Artikelnummer !== null ||
      artikel.Artikelnummer !== "" ||
      artikel.Artikelnummer !== undefined
    ) {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
    setArtikelNr(artikel.Artikelnummer);
    const beschikbaar = getBeschikbaar(artikel);
    setArtikelAantal(beschikbaar);
  };

  const close = () => {
    setArtikelAantal(0);
    setAantal(0);
    setDisableFields(true);
    props.toggle();
  };

  const getBeschikbaar = artikel => {
    return (
      (parseInt(artikel.Aantal) || 0) -
      (parseInt(artikel.AantalUitgeleend) || 0) -
      (parseInt(artikel.AantalDefect) || 0) +
      (parseInt(artikel.AantalIngenomen) || 0)
    );
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter-add"
      centered
      onHide={close}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter-add">Artikel selecteren</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Col xs={3}>
              <FormGroup>
                <FormLabel>Aantal</FormLabel>
                <FormControl
                  type="number"
                  required
                  onChange={e => (e.target.value < 0 ? setAantal(0) : setAantal(e.target.value))}
                  onBlur={e =>
                    e.target.value > artikelAantal
                      ? setAantal(artikelAantal)
                      : setAantal(e.target.value)
                  }
                  disabled={disableFields}
                  value={aantal}
                  min={0}
                />
              </FormGroup>
            </Col>
            <Col xs={9}>
              <FormGroup>
                <FormLabel>Bijzonderheden</FormLabel>
                <FormControl
                  type="text"
                  disabled={disableFields}
                  onChange={e => setBijzonderheden(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12}>
              <FormLabel>Artikel</FormLabel>
              <FormControl as="select" required onChange={getArtikelnummer}>
                {artikelenLijst.map(artikel => {
                  return (
                    <option key={artikel.Artikelnummer} value={artikel.Artikelnummer}>
                      {artikel.Artikelnummer}, {artikel.Omschrijving} (
                      {getBeschikbaar(artikel)} beschikbaar)
                    </option>
                  );
                })}
              </FormControl>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} variant="dark">
            Annuleren
          </Button>
          <Button type="submit" variant="primary">
            Toevoegen
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const API = process.env.REACT_APP_AUTH_API_URI;
export default class Uitlenen extends React.Component {
  state = {
    cancel: false,
    showModal: false,
    showArtikelInfo: false,
    artikelLijst: [],
    artikelInfo: null,
    adding: false,
    // artikel data
    hal: "",
    veld: "",
    studentdocent: "",
    telefoon: "",
    datumUitgifte: "",
    tijdUitgifte: "",
    uitleenArtikelen: []
  };

  async componentDidMount() {
    await Axios.get(API + "/artikelen").then(res => {
      this.setState({ artikelLijst: res.data });
    });
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleSubmit = e => {
    if (e) e.preventDefault();
    const _uitgifte = {
      hal: this.state.hal,
      veld: this.state.veld,
      studentdocent: this.state.studentdocent,
      telefoon: this.state.telefoon,
      datumUitgifte: this.state.datumUitgifte,
      tijdUitgifte: this.state.tijdUitgifte,
      uitleenArtikelen: this.state.uitleenArtikelen
    };
    this.setState({ adding: true });
    Axios.post(API + "/lenen/uitlenen", _uitgifte)
      .then(res => this.setState({ adding: false, cancel: true }))
      .catch(err => {
        this.setState({ adding: false });
        console.error(err);
      });
  };

  addArtikelToList = artikel => {
    let _ua = [...this.state.uitleenArtikelen];
    _ua.push(artikel);
    this.setState({ uitleenArtikelen: _ua });
  };

  removeArtikelFromList = artikelnr => {
    let _ua = [...this.state.uitleenArtikelen];
    _ua = _ua.filter(a => {
      return a.artikelnr !== artikelnr;
    });
    this.setState({ uitleenArtikelen: _ua });
  };

  render() {
    return (
      <Container className="p-5">
        {this.state.cancel ? <Redirect to="/lenen" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Uitlenen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ cancel: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Hal</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier de hal in"
                        onChange={e => this.setState({ hal: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Veld</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier het veld in"
                        onChange={e => this.setState({ veld: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Student/Docent</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier student/docent in"
                        required
                        onChange={e => this.setState({ studentdocent: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Telefoon Student/Docent</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Vul hier het telefoonnummer in"
                        onChange={e => this.setState({ telefoon: e.target.value })}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Datum uitgifte</FormLabel>
                      <FormControl
                        type="date"
                        placeholder="Vul hier datum van uitgifte in"
                        onChange={e => this.setState({ datumUitgifte: e.target.value })}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>Tijd uitgifte</FormLabel>
                      <FormControl
                        type="time"
                        placeholder="Vul hier de tijd van uitgifte in"
                        onChange={e => this.setState({ tijdUitgifte: e.target.value })}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Form.Row>
                <Table responsive striped size="sm">
                  <thead>
                    <tr>
                      <th>Aantal</th>
                      <th>Sportmateriaal</th>
                      <th>Bijzonderheden</th>
                      <th className="text-right">
                        <Button size="sm" onClick={this.toggleModal}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uitleenArtikelen.map(artikel => {
                      return (
                        <tr key={artikel.artikelnr}>
                          <td>{artikel.aantal}</td>
                          <td>{artikel.artikelnr}</td>
                          <td>{artikel.bijzonderheden}</td>
                          <td className="text-right">
                            <Button
                              size="sm"
                              variant="dark"
                              onClick={() => this.removeArtikelFromList(artikel.artikelnr)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Button
                  variant="secondary"
                  block
                  type="submit"
                  disabled={this.state.uitleenArtikelen.length <= 0}
                >
                  Voltooi uitgifte
                  {this.state.adding ? (
                    <Spinner className="ml-2" animation="border" size="sm" variant="white" />
                  ) : null}
                </Button>
              </Form>
            </Col>
          </Container>
        </Container>
        <ItemSelectModal
          show={this.state.showModal}
          toggle={this.toggleModal}
          artikelen={this.state.artikelLijst}
          addArtikel={this.addArtikelToList}
        />
      </Container>
    );
  }
}
