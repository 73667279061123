import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Spinner } from "react-bootstrap";
import _ from "underscore";
import Axios from "axios";
import { ColorSquare } from "../../components/LayoutComponents";

export default ({ artikelNr, shown, close }) => {
  const [artikelInfo, setArtikelInfo] = useState({});

  const fetchData = async () => {
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/artikelen/info/" + artikelNr)
      .then(res => {
        setArtikelInfo(res.data);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (artikelNr !== null) fetchData();

    return () => setArtikelInfo({});
    // eslint-disable-next-line
  }, [artikelNr]);

  return (
    <Modal show={shown} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Artikel informatie ({artikelNr})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!_.isEmpty(artikelInfo) ? (
          <Table bordered size="sm" className="bg-white m-0 mb-3 font-weight-normal">
            <tbody>
              <tr>
                <td>Omschrijving</td>
                <td>{artikelInfo.Omschrijving}</td>
              </tr>
              <tr>
                <td>Activiteit</td>
                <td>{artikelInfo.Activiteiten}</td>
              </tr>
              <tr>
                <td>Groep</td>
                <td>{artikelInfo.Groep}</td>
              </tr>
              <tr>
                <td>Merk</td>
                <td>{artikelInfo.Merknaam}</td>
              </tr>
              <tr>
                <td>Eigenaar</td>
                <td>
                  {artikelInfo.Afkorting} - {artikelInfo.Plaats} ({artikelInfo.Straat}{" "}
                  {artikelInfo.Huisnummer})
                </td>
              </tr>
              <tr>
                <td>Magazijn</td>
                <td>{artikelInfo.Magazijn}</td>
              </tr>
              <tr>
                <td>Locatie</td>
                <td>{artikelInfo.Locaties}</td>
              </tr>
              <tr>
                <td>Totale voorraad</td>
                <td>{artikelInfo.Aantal}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div className="text-center">
            <Spinner size="lg" variant="primary" animation="border" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={close}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
