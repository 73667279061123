import React, { Component } from "react";
import {
  Col,
  Container,
  InputGroup,
  Table,
  Spinner,
  Row,
  FormControl,
  Button,
  Modal,
  Form,
  FormLabel
} from "react-bootstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as editIcon,
  faTrash as deleteIcon,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import PermissionValidation from "../../../components/auth/PermissionValidation";

const API = process.env.REACT_APP_AUTH_API_URI;
export default class BeheerLeverancier extends Component {
  state = {
    data: { leverancieren: [] },
    isFetching: false,
    showDeleteModal: false,
    showAddModal: false,
    showEditModal: false,
    isDeleting: false,
    isAdding: false,
    isEditing: false,
    leverancierToDelete: 0,
    leverancierToEdit: 0,
    leverancier: "",
    filter: ""
  };

  ClearState = () => {
    this.setState({
      leverancierToDelete: 0,
      leverancierToEdit: 0,
      leverancier: "",
    });
  };

  GetLeverancierData = async () => {
    this.setState({ isFetching: true });
    await Promise.all([Axios.get(API + "/leveranciers")]).then(res => {
      this.setState(
        {
          data: {
            leverancieren: res[0].data
          },
          isFetching: false
        },
        () => this.ClearState()
      );
    });
  };

  DeleteLeverancierData = async () => {
    this.setState({ isDeleting: true });
    await Axios.delete(API + "/leveranciers/" + this.state.leverancierToDelete)
      .then(res => {
        this.GetLeverancierData();
      })
      .catch(err => {
        this.GetLeverancierData();
      });
    this.setState({ showDeleteModal: false, isDeleting: false, leverancierToDelete: 0 }, () =>
      this.ClearState()
    );
  };

  AddLeverancierData = async event => {
    event.preventDefault();
    this.setState({ isAdding: true });
    await Axios.post(API + "/leveranciers", {
      leverancier: this.state.leverancier,
    })
      .then(res => this.GetLeverancierData())
      .catch(this.GetLeverancierData());
    this.setState({ showAddModal: false, isAdding: false, leverancier: "" }, () =>
      this.ClearState()
    );
  };

  EditLeverancierData = async event => {
    event.preventDefault();
    this.setState({ isEditing: true });
    await Axios.put(API + "/leveranciers/" + this.state.leverancierToEdit, {
      leverancier: this.state.leverancier,
    })
      .then(res => this.GetLeverancierData())
      .catch(this.GetLeverancierData());
    this.setState(
      {
        showEditModal: false,
        isEditing: false
      },
      () => this.ClearState()
    );
  };

  componentDidMount() {
    this.GetLeverancierData();
  }

  render() {
    return (
      <Container fluid className="p-0 shadow">
        <Container className="bg-primary rounded-top p-3" fluid>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6}>
              <h1 className="m-0 text-white mb-3 mb-md-0">Leveranciers</h1>
            </Col>
            <Col md={6}>
              <Col className="p-0 float-right" md={12} lg={10} xl={8}>
                <Button
                  className="float-right"
                  variant="outline-light"
                  onClick={() => this.setState({ showAddModal: true })}
                  block
                >
                  Nieuwe leverancier toevoegen
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="bg-light p-4 rounded-bottom" fluid>
          <InputGroup className="mb-4" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="zoekfilter">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Type hier om te filteren..."
              aria-label="Leverancier zoekfilter"
              aria-describedby="zoekfilter"
              onChange={e => this.setState({ filter: e.target.value })}
            />
          </InputGroup>
          <Col className="p-0">
            {this.state.isFetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                bordered
                size="sm"
                striped
                className="bg-white m-0 font-weight-normal"
                responsive
              >
                <thead>
                  <tr>
                    <th>Leverancier</th>
                    {PermissionValidation("kanWijzigen" || "kanVerwijderen", <th>Acties</th>)}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.leverancieren
                    .filter(l => {
                      l.filterThrough = false;
                      if (this.state.filter.length > 0) {
                        // eslint-disable-next-line
                        for (let index in l) {
                          if (l[index] !== null && l.filterThrough === false) {
                            l.filterThrough = l[index]
                              .toString()
                              .toLowerCase()
                              .includes(this.state.filter.toString().toLowerCase());
                          }
                        }
                        return l.filterThrough;
                      } else return true;
                    })
                    .map(leverancier => {
                      return (
                        <tr key={leverancier.ID}>
                          <td>{leverancier.Leverancier}</td>
                          {PermissionValidation(
                            "kanWijzigen" || "kanVerwijderen",
                            <td>
                              {PermissionValidation(
                                "kanWijzigen",
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() =>
                                    this.setState({
                                      leverancierToEdit: leverancier.ID,
                                      showEditModal: true,
                                      leverancier: leverancier.Leverancier
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={editIcon} />
                                </Button>
                              )}
                              &nbsp;
                              {PermissionValidation(
                                "kanVerwijderen",
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      leverancierToDelete: leverancier.ID,
                                      showDeleteModal: true
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={deleteIcon} />
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Col>
        </Container>
        {/* DELETE LEVERANCIER */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-delete"
          centered
          onHide={() => this.setState({ showDeleteModal: false })}
          show={this.state.showDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-delete">
              Leverancier verwijderen
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Weet u zeker dat u deze leverancier wilt verwijderen? Deze actie kan niet ongedaan
              gemaakt worden.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showDeleteModal: false })} variant="dark">
              Annuleren
            </Button>
            <Button onClick={this.DeleteLeverancierData} variant="danger">
              Verwijderen
              {this.state.isDeleting ? (
                <Spinner variant="white" animation="border" size="sm" className="ml-1" />
              ) : null}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* EDIT LEVERANCIER */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showEditModal: false })}
          show={this.state.showEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Leverancier bewerken</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.EditLeverancierData}>
            <Modal.Body>
              <Form.Row>
                <Col md={12}>
                  <FormLabel>Leverancier</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Vul hier de leveranciersnaam in.."
                    required
                    onChange={e => this.setState({ leverancier: e.target.value })}
                    defaultValue={this.state.leverancier}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showEditModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Bewerken
                {this.state.isEditing ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* ADD LEVERANCIER */}
        <Modal
          aria-labelledby="contained-modal-title-vcenter-add"
          centered
          onHide={() => this.setState({ showAddModal: false })}
          show={this.state.showAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add">Leverancier toevoegen</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.AddLeverancierData}>
            <Modal.Body>
              <Form.Row>
                <Col md={12}>
                  <FormLabel>Leverancier</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Vul hier de leveranciersnaam in.."
                    required
                    onChange={e => this.setState({ leverancier: e.target.value })}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showAddModal: false })} variant="dark">
                Annuleren
              </Button>
              <Button type="submit" variant="secondary">
                Toevoegen
                {this.state.isAdding ? (
                  <Spinner variant="white" animation="border" size="sm" className="ml-1" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    );
  }
}
