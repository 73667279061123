import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner,
  Form,
  Col
} from "react-bootstrap";
import moment from "moment";
import Axios from "axios";
import { toast } from "react-toastify";

const API = process.env.REACT_APP_AUTH_API_URI;
export default ({ shown, toggle, fetch }) => {
  // -- gegevens
  const [artikelNr, setArtikelNr] = useState(null);
  const [datumDefect, setDatumDefect] = useState(moment().format("YYYY-MM-DD"));
  const [aantal, setAantal] = useState(1);
  const [maxAantal, setMaxAantal] = useState(0);
  const [opmerkingen, setOpmerkingen] = useState("");
  // -- overig
  const [artikelData, setArtikelData] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [disableFields, setDisableFields] = useState(true);

  const fetchData = async () => {
    setUpdating(true);
    await Axios.get(API + "/artikelen")
      .then(res => {
        setArtikelData(res.data);
      })
      .catch(err => console.error(err));
    setUpdating(false);
  };

  const HandleAantal = aantal => {
    if (aantal < 1) setAantal(1);
    if (aantal > maxAantal) setAantal(maxAantal);
    else setAantal(parseInt(aantal));
  };

  const HandleDate = date => {
    setDatumDefect(moment(date).format("YYYY-MM-DD"));
  };

  const HandleRegister = async e => {
    if (e) e.preventDefault();
    const _defect = {
      datumDefect: datumDefect,
      aantal: aantal,
      opmerkingen: opmerkingen
    };
    await Axios.post(API + "/artikelen/defecten/nieuw/" + artikelNr, _defect)
      .then(res => {
        toast.success(res.data.message);
        fetch();
        close();
      })
      .catch(err => console.error(err));
  };

  const getArtikelnummer = e => {
    const { Artikelnummer, Aantal } = [...artikelData][e.target.selectedIndex];
    if (Artikelnummer !== null || Artikelnummer !== "" || Artikelnummer !== undefined) {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
    setArtikelNr(Artikelnummer);
    setMaxAantal(Aantal);
    setAantal(1);
  };

  const close = () => {
    setAantal(0);
    setMaxAantal(0);
    setArtikelNr(null);
    setDisableFields(true);
    toggle();
  };

  useEffect(() => {
    fetchData();
    return () => setArtikelData([]);
  }, []);

  return (
    <Modal show={shown} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Defect registreren</Modal.Title>
      </Modal.Header>
      <Form onSubmit={HandleRegister}>
        <Modal.Body>
          <Form.Row>
            <Col md={9}>
              <FormGroup>
                <FormLabel>Artikel</FormLabel>
                <FormControl as="select" required onChange={getArtikelnummer}>
                  {artikelData.map(artikel => {
                    return (
                      <option key={artikel.Artikelnummer} value={artikel.Artikelnummer}>
                        {artikel.Artikelnummer} - {artikel.Omschrijving} (
                        {artikel.Merknaam})
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <FormLabel>Aantal</FormLabel>
                <FormControl
                  type="number"
                  required
                  onChange={e => (e.target.value < 0 ? setAantal(0) : setAantal(e.target.value))}
                  onBlur={e => HandleAantal(e.target.value)}
                  disabled={disableFields}
                  value={aantal}
                  min={1}
                />
              </FormGroup>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={9}>
              <FormGroup>
                <FormLabel>Opmerkingen</FormLabel>
                <FormControl type="text" onChange={e => setOpmerkingen(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <FormLabel>Datum uitgifte</FormLabel>
                <FormControl
                  type="date"
                  onChange={e => HandleDate(e.target.value)}
                  required
                  value={datumDefect}
                />
              </FormGroup>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={close}>
            Annuleren
          </Button>
          <Button variant="primary" type="submit">
            Registreren
            {updating ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-1" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
