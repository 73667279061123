import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Navbar, Nav, Image, Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars as MenuOpenIcon,
  faTimes as MenuCloseIcon
} from "@fortawesome/free-solid-svg-icons";
import NavigationContent from "../data/NavigationContent";
import styles from "./Navigationbar.module.scss";
import HanLogo from "../assets/images/logo-small.png";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import PermissionValidation from "./auth/PermissionValidation";

class Avatar extends React.Component {
  render() {
    const handleClick = e => {
      e.preventDefault();

      this.props.onClick(e);
    };
    return (
      <Image
        onClick={e => handleClick(e)}
        src={`../assets/avatars/avatar.png`}
        roundedCircle
        className={styles.ImageHeight}
        style={{ cursor: "pointer" }}
      />
    );
  }
}

const CustomDropdown = () => {
  const [loggedOut, setLoggedOut] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [toProfile, setToProfile] = React.useState(false);

  const GetUsername = () => {
    try {
      const token = jwt.decode(localStorage.getItem("refresh-token"));
      setUsername(token.user.naam.toString());
    } catch {
      return null;
    }
  };
  GetUsername();

  const Logout = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    toast.warn("U bent nu uitgelogd");
    setLoggedOut(true);
  };

  return loggedOut ? (
    <Redirect to="/login" />
  ) : (
    <Dropdown alignRight>
      {toProfile ? <Redirect to="/profile" /> : null}
      <Dropdown.Toggle as={Avatar} id="dropdown-custom-components" />
      <Dropdown.Menu>
        <Dropdown.Header>{username}</Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            setToProfile(true);
            setTimeout(() => {
              setToProfile(false);
            }, 10);
          }}
        >
          Mijn account
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="3" onClick={() => Logout()}>
          Uitloggen
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Navigationbar = props => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const mediaQuery = windowWidth <= 992;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Navbar bg="light" className={styles.Navbar}>
      {mediaQuery ? (
        <React.Fragment>
          <Nav onClick={props.sidebarToggle} className="mr-auto">
            <p
              className={[
                styles.NavbarToggle,
                props.sidebarOpen ? styles.NavbarIconShow : styles.NavbarIconHide
              ].join(" ")}
            >
              {props.sidebarOpen ? (
                <FontAwesomeIcon icon={MenuCloseIcon} />
              ) : (
                <FontAwesomeIcon icon={MenuOpenIcon} />
              )}
            </p>
          </Nav>
          <CustomDropdown />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Navbar.Brand href="/">
            <Image
              src={HanLogo}
              className={"d-inline-block align-top " + styles.ImageHeight}
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Nav className="ml-auto mr-5">
            {NavigationContent.map(item => {
              if (item.admin) {
                return (
                  <div key={item.title}>
                    {PermissionValidation(
                      "isAdmin",
                      <NavbarItem item={item} styles={styles} index={item.title} />
                    )}
                  </div>
                );
              }
              if (item.perm !== undefined) {
                return (
                  <div key={item.title}>
                    {PermissionValidation(
                      item.perm,
                      <NavbarItem item={item} styles={styles} index={item.title} />
                    )}
                  </div>
                );
              } else
                return (
                  <NavbarItem item={item} styles={styles} index={item.title} key={item.title} />
                );
            })}
          </Nav>
          <CustomDropdown />
        </React.Fragment>
      )}
    </Navbar>
  );
};

const NavbarItem = ({ item, styles, index }) => {
  return (
    <LinkContainer
      to={item.uri}
      exact={item.exact ? item.exact : false}
      key={index}
      className={styles.NavbarLink}
      activeClassName={styles.NavbarLinkActive}
    >
      <Nav.Link eventKey={index} active={false}>
        {item.title}
      </Nav.Link>
    </LinkContainer>
  );
};

export default withRouter(Navigationbar);
