import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner,
  FormText
} from "react-bootstrap";
import Axios from "axios";
import moment from "moment";
import ArtikelWijzigenLeveranciers from "./ArtikelWijzigenLeveranciers";

const API = process.env.REACT_APP_AUTH_API_URI;

class ArtikelWijzigen extends Component {
  state = {
    leavePage: false,
    isFetching: false,
    data: {
      activiteiten: [],
      groepen: [],
      merken: [],
      eigenaren: [],
      magazijnen: [],
      leveranciers: []
    },
    artikelnummer: "",
    omschrijving: "",
    merkid: 0,
    eigenaarid: 0,
    old_eigenaarid: 0,
    activiteitid: 0,
    old_activiteitid: 0,
    groepid: 0,
    old_groepid: 0,
    magazijnid: 0,
    old_magazijnid: 0,
    locatie: "",
    old_locatie: "",
    aantal: 0,
    leveranciers: []
  };

  async componentDidMount() {
    this.setState({ isFetching: true });
    await Promise.all([
      Axios.get(API + "/activiteiten"),
      Axios.get(API + "/groepen"),
      Axios.get(API + "/merken"),
      Axios.get(API + "/eigenaren"),
      Axios.get(API + "/magazijnen"),
      Axios.get(API + "/artikelen/wijzig/" + this.props.match.params.id),
      Axios.get(API + "/leveranciers")
    ]).then(res => {
      const _art = res[5].data;

      this.setState(
        {
          data: {
            activiteiten: res[0].data,
            groepen: res[1].data,
            merken: res[2].data,
            eigenaren: res[3].data,
            magazijnen: res[4].data,
            leveranciers: res[6].data
          },
          artikelnummer: _art.Artikelnummer,
          omschrijving: _art.Omschrijving,
          merkid: _art.Merk_id,
          eigenaarid: _art.Eigenaar_id,
          old_eigenaarid: _art.Eigenaar_id,
          activiteitid: _art.Activiteit_id,
          old_activiteitid: _art.Activiteit_id,
          groepid: _art.Artikelgroep_id,
          old_groepid: _art.Artikelgroep_id,
          magazijnid: _art.Magazijn_id,
          old_magazijnid: _art.Magazijn_id,
          locatie: _art.Locatie,
          old_locatie: _art.Locatie,
          aantal: _art.Aantal,
          leveranciers: _art.Leveranciers,
          isFetching: false
        }
      );
    });
  }

  SetLeveranciers = leveranciers => this.setState({ leveranciers: leveranciers });

  GetMerkId = e =>
    this.setState({
      merkid: [...this.state.data.merken][e.target.selectedIndex].ID
    });

  GetEigenaarId = e =>
    this.setState({
      eigenaarid: [...this.state.data.eigenaren][e.target.selectedIndex].ID
    });

  GetActiviteitId = e =>
    this.setState({
      activiteitid: [...this.state.data.activiteiten][e.target.selectedIndex].ID
    });

  GetGroepId = e =>
    this.setState({
      groepid: [...this.state.data.groepen][e.target.selectedIndex].ID
    });

  GetMagazijnId = e =>
    this.setState({
      magazijnid: [...this.state.data.magazijnen][e.target.selectedIndex].ID
    });

  HandleSubmit = event => {
    if (event) event.preventDefault();
    const _ts = this.state;
    Axios.put(API + "/artikelen/" + this.props.match.params.id, {
      omschrijving: _ts.omschrijving,
      merkid: _ts.merkid,
      eigenaarid: _ts.eigenaarid,
      old_eigenaarid: _ts.old_eigenaarid,
      activiteitid: _ts.activiteitid,
      old_activiteitid: _ts.old_activiteitid,
      groepid: _ts.groepid,
      old_groepid: _ts.old_groepid,
      magazijnid: _ts.magazijnid,
      old_magazijnid: _ts.old_magazijnid,
      locatie: _ts.locatie,
      old_locatie: _ts.old_locatie,
      aantal: _ts.aantal,
      leveranciers: _ts.leveranciers
    })
      .then(response => this.setState({ toegevoegd: true, leavePage: true }))
      .catch(error => console.error(error));
  };

  render() {
    return (
      <Container className="p-5">
        {this.state.leavePage ? <Redirect to="/artikelen" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Artikel wijzigen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ leavePage: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <Col className="p-0">
              {this.state.isFetching ? (
                <div className="text-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <Form onSubmit={this.HandleSubmit}>
                  <Form.Row>
                    <Col md={12} className="mb-3">
                      <FormLabel>Omschrijving</FormLabel>
                      <FormControl
                        type="text"
                        onChange={e => this.setState({ omschrijving: e.target.value })}
                        placeholder="Vul hier de omschrijving in"
                        defaultValue={this.state.omschrijving}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Merk</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetMerkId}
                          defaultValue={this.state.merkid}
                        >
                          {this.state.data.merken.map(merk => {
                            return (
                              <option key={merk.ID} value={merk.ID}>
                                {merk.Merknaam}
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Eigenaar</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetEigenaarId}
                          defaultValue={this.state.eigenaarid}
                        >
                          {this.state.data.eigenaren.map(eigenaar => {
                            return (
                              <option key={eigenaar.ID} value={eigenaar.ID}>
                                {eigenaar.Afkorting} - {eigenaar.Plaats} ({eigenaar.Straat}{" "}
                                {eigenaar.Huisnummer})
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Activiteit</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetActiviteitId}
                          defaultValue={this.state.activiteitid}
                        >
                          {this.state.data.activiteiten.map(activiteit => {
                            return (
                              <option key={activiteit.ID} value={activiteit.ID}>
                                {activiteit.Activiteit}
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Groep</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetGroepId}
                          defaultValue={this.state.groepid}
                        >
                          {this.state.data.groepen.map(groep => {
                            return (
                              <option key={groep.ID} value={groep.ID}>
                                {groep.Artikelgroep}
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Magazijn</FormLabel>
                        <FormControl
                          as="select"
                          onChange={this.GetMagazijnId}
                          defaultValue={this.state.magazijnid}
                        >
                          {this.state.data.magazijnen.map(magazijn => {
                            return (
                              <option key={magazijn.ID} value={magazijn.ID}>
                                {magazijn.Magazijn}
                              </option>
                            );
                          })}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <FormLabel>Locatie</FormLabel>
                        <FormControl
                          type="text"
                          onChange={e =>
                            this.setState({
                              locatie: e.target.value.toString().toLowerCase()
                            })
                          }
                          placeholder="Vul hier de locatie in"
                          defaultValue={this.state.locatie}
                          required
                        />
                        <FormText>Dit is de locatie binnen het magazijn</FormText>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <FormLabel>Aantal</FormLabel>
                        <FormControl
                          type="number"
                          min={0}
                          defaultValue={this.state.aantal}
                          required
                          onChange={e => this.setState({ aantal: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <ArtikelWijzigenLeveranciers
                      data={this.state.data.leveranciers}
                      leveranciers={this.state.leveranciers}
                      setLeveranciers={this.SetLeveranciers}
                    />
                  </Form.Row>
                  <hr />
                  <Button type="submit" variant="secondary" block className="mt-3">
                    Wijzig artikel
                  </Button>
                </Form>
              )}
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default ArtikelWijzigen;
