import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArtikelInfo from "../ArtikelInfo";
import ArtikelDefectOverzichtItem from "./ArtikelDefectOverzichtItem";
import {
  faWarehouse as DefectIcon,
  faWrench as RepairIcon
} from "@fortawesome/free-solid-svg-icons";
import ArtikelDefectRegister from "./ArtikelDefectRegister";

export default () => {
  const [cancel, setCancel] = useState(false);
  const [artikelDefecten, setArtikelDefecten] = useState([]);
  const [artikelReparaties, setArtikelReparaties] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [artikelNr, setArtikelNr] = useState(null);
  // -- modals
  const [showArtikelInfo, setShowArtikelInfo] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const toggleArtikelInfo = artikelNr => {
    if (showArtikelInfo || artikelNr === null) {
      setArtikelNr(null);
      setShowArtikelInfo(false);
    } else {
      setArtikelNr(artikelNr);
      setShowArtikelInfo(true);
    }
  };

  const handleRegisterToggle = () => {
    if (!showRegister) setShowRegister(true);
    else setShowRegister(false);
  };

  const fetchData = async () => {
    setFetching(true);
    await Axios.get(process.env.REACT_APP_AUTH_API_URI + "/artikelen/defecten")
      .then(res => {
        setArtikelDefecten(res.data.defecten);
        setArtikelReparaties(res.data.reparaties);
      })
      .catch(err => console.error(err));
    setFetching(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setArtikelDefecten([]);
    };
  }, []);

  return (
    <>
      {cancel ? <Redirect to="/artikelen" /> : null}
      <ArtikelInfo
        shown={showArtikelInfo}
        artikelNr={artikelNr}
        close={() => toggleArtikelInfo()}
      />
      <ArtikelDefectRegister shown={showRegister} toggle={handleRegisterToggle} fetch={fetchData} />
      <Container className="p-5" fluid>
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Artikel defecten</h1>
              </Col>
              <Col md={6}>
                <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={4}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => setCancel(true)}
                  >
                    Artikel overzicht
                  </Button>
                </Col>
                <Col className="p-0 float-right mb-2 mb-xl-0 ml-lg-2" md={10} lg={6} xl={4}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={handleRegisterToggle}
                  >
                    Defect registreren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <div className="w-100 bg-secondary text-white shadow-sm p-3 rounded mb-1">
              <h4 className="m-0 p-0">
                <FontAwesomeIcon icon={DefectIcon} style={{ width: 40 }} className="mr-2" />
                Defect
              </h4>
            </div>
            {fetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <ArtikelDefectOverzichtItem
                artikelDefecten={artikelDefecten}
                toggle={toggleArtikelInfo}
                variant="defect"
                fetch={fetchData}
              />
            )}
            <div className="w-100 bg-secondary text-white shadow-sm p-3 rounded mb-1 mt-3">
              <h4 className="m-0 p-0">
                <FontAwesomeIcon icon={RepairIcon} style={{ width: 40 }} className="mr-2" />
                Gerepareerd
              </h4>
            </div>
            {fetching ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <ArtikelDefectOverzichtItem
                artikelDefecten={artikelReparaties}
                toggle={toggleArtikelInfo}
                variant="reparatie"
                fetch={fetchData}
              />
            )}
          </Container>
        </Container>
      </Container>
    </>
  );
};
