import React, { useState } from "react";
import { Modal, Button, FormGroup, FormLabel, FormControl, Spinner } from "react-bootstrap";
import moment from "moment";
import Axios from "axios";
import { toast } from "react-toastify";

const API = process.env.REACT_APP_AUTH_API_URI;
export default ({ shown, toggle, anr, datDefect, fetch }) => {
  const [datumReparatie, setDatumReparatie] = useState(moment().format("YYYY-MM-DD"));
  const [opmerking, setOpmerking] = useState("");
  const [updating, setUpdating] = useState(false);

  const HandleRepair = async () => {
    setUpdating(true);
    await Axios.post(API + "/artikelen/defecten/reparatie/" + anr, {
      datumDefect: datDefect,
      datumReparatie: datumReparatie,
      opmerking: opmerking
    })
      .then(res => {
        toast.success(res.data.message);
        fetch();
        toggle();
      })
      .catch(err => {
        console.error(err);
      });
    setUpdating(false);
  };

  return (
    <Modal show={shown} onHide={toggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Artikel reparatie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel>Reparatiedatum</FormLabel>
          <FormControl
            type="date"
            onChange={e => setDatumReparatie(e.target.value)}
            value={datumReparatie}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Opmerkingen na reparatie</FormLabel>
          <FormControl
            type="text"
            onChange={({ target }) => setOpmerking(target.value)}
            value={opmerking}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={toggle}>
          Annuleren
        </Button>
        <Button variant="success" onClick={HandleRepair}>
          Registreren
          {updating ? (
            <Spinner variant="white" animation="border" size="sm" className="ml-1" />
          ) : null}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
