import React from "react";
import { Container, Col, Tabs, Tab } from "react-bootstrap";
// beheer
import BeheerMerken from "./Merken/Merken";
import BeheerEigenaren from "./Eigenaren/Eigenaren";
import BeheerArtikelGroepen from "./Groepen/Groepen";
import BeheerActiviteiten from "./Activiteiten/Activiteiten";
import BeheerLeveranciers from "./Leveranciers/Leveranciers";
import BeheerLogboek from "./Logboek/Logboek";

class Beheer extends React.Component {
  render() {
    return (
      <div>
        <Container className="p-5" fluid>
          <Col xs={12} className="bg-light p-0 m-0 rounded shadow-sm">
            <Tabs defaultActiveKey="logboek" id="beheer-tab-example" transition={false}>
              <Tab eventKey="merk" title="Merken">
                <StyledCol>
                  <BeheerMerken />
                </StyledCol>
              </Tab>
              <Tab eventKey="artikelgroep" title="Artikelgroepen">
                <StyledCol>
                  <BeheerArtikelGroepen />
                </StyledCol>
              </Tab>
              <Tab eventKey="activiteit" title="Activiteiten">
                <StyledCol>
                  <BeheerActiviteiten />
                </StyledCol>
              </Tab>
              <Tab eventKey="eigenaar" title="Eigenaren">
                <StyledCol>
                  <BeheerEigenaren />
                </StyledCol>
              </Tab>
              <Tab eventKey="leverancier" title="Leveranciers">
                <StyledCol>
                  <BeheerLeveranciers />
                </StyledCol>
              </Tab>
              <Tab eventKey="logboek" title="Logboek">
                <StyledCol>
                  <BeheerLogboek />
                </StyledCol>
              </Tab>
            </Tabs>
          </Col>
        </Container>
      </div>
    );
  }
}

const StyledCol = props => {
  return (
    <Col xs={12} className="p-4">
      {props.children}
    </Col>
  );
};

export default Beheer;
