import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Axios from "axios";
import XLSX from "xlsx";
import ArtikelExcelInvullijst from "./ArtikelExcelInvullijst";

export default ({ leavePage }) => {
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [openingLoading, setOpeningLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleFileChange = e => {
    setOpeningLoading(true);
    let fileObj = e.target.files[0];
    if (fileObj === undefined) return setOpeningLoading(false);
    const reader = new FileReader();
    reader.onload = e => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        cellNF: false,
        cellText: false
      });
      try {
        let excelJson = [];
        const wsName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsName];
        excelJson = XLSX.utils.sheet_to_json(worksheet, { dateNF: "YYYY-MM-DD" });
        setFile(excelJson);
        try {
          setFileName(fileObj.name);
        } catch (err) {
          setFileName("Onbekend.xls(x)");
        }
      } catch (err) {
        setOpeningLoading(false);
        clearFile();
        return toast.warn(
          "Er is is iets misgegaan tijdens het converteren, weet u zeker dat dit bestand correct is?"
        );
      }
      setOpeningLoading(false);
    };
    reader.onerror = e => {
      toast.warn(
        "Er is iets misgegaan tijdens het converteren, weet u zeker dat dit bestand correct is?"
      );
    };
    reader.readAsBinaryString(fileObj);
  };

  const clearFile = () => {
    setFileName(null);
    setFile(null);
  };

  const handleSubmit = async e => {
    if (e) e.preventDefault();
    setSubmitLoading(true);
    await Axios.post(process.env.REACT_APP_AUTH_API_URI + "/artikelen/import", { excelFile: file })
      .then(res => {
        setSubmitLoading(false);
        toast.success("Het excel bestand is succesvol geïmporteerd.");
        leavePage();
      })
      .catch(error => {
        setSubmitLoading(false);
      });
  };

  return (
    <Col xs={12} className="p-0">
      <ArtikelExcelInvullijst />
      <hr />
      <Row>
        <Col xs={12} md={8} className="d-flex justify-content-start align-items-center">
          <p className="m-0 font-weight-bold">
            {fileName === null ? (
              "Kies het artikel bestand om te beginnen.."
            ) : (
              <span onClick={clearFile}>
                Gekozen bestand: '<i>{fileName}</i>'&nbsp;
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="ml-1"
                  onClick={clearFile}
                  disabled={openingLoading}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </span>
            )}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <label className="btn btn-dark d-block m-0" style={{ cursor: "pointer" }}>
            Bladeren
            {openingLoading ? (
              <Spinner variant="white" animation="border" size="sm" className="ml-2" />
            ) : null}
            <input type="file" hidden onChange={handleFileChange} accept=".xls, .xlsx" />
          </label>
        </Col>
      </Row>
      <p className="text-muted my-4">
        <i>
          <b>BELANGRIJK:</b> Als een artikel uit het document niet wordt toegevoegd dan was deze
          waarschijnlijk niet correct ingevuld.
        </i>
      </p>
      <hr className="my-4" />
      <Button block variant="secondary" onClick={handleSubmit} disabled={file === null}>
        Importeer artikelen
        {submitLoading ? (
          <Spinner variant="white" animation="border" size="sm" className="ml-2" />
        ) : null}
      </Button>
    </Col>
  );
};
