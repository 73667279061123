import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import Axios from "axios";
import InnemenItem from "./InnemenItem";
import InnemenModal from "./InnemenModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse as IngenomenIcon } from "@fortawesome/free-solid-svg-icons";

const API = process.env.REACT_APP_AUTH_API_URI;
export default class Innemen extends React.Component {
  state = {
    cancel: false,
    hasFetched: false,
    uitgiften: [],
    selectedId: null,
    showModal: false
  };

  closeModal = () => {
    this.setState({ showModal: false, selectedId: null });
  };

  handleInnameModal = ID => {
    this.setState({ selectedId: ID, showModal: true });
  };

  fetchData = async () => {
    this.setState({ hasFetched: false });
    await Axios.get(API + "/lenen/overzicht")
      .then(res => {
        this.setState({ uitgiften: res.data.openstaand, hasFetched: true });
      })
      .catch(err => {
        this.setState({ hasFetched: true });
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Container className="p-5">
        {this.state.cancel ? <Redirect to="/lenen" /> : null}
        <Container fluid className="p-0 shadow">
          <Container className="bg-primary rounded-top p-3" fluid>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={8} lg={6}>
                <h1 className="m-0 text-white mb-3 mb-md-0">Innemen</h1>
              </Col>
              <Col md={4} lg={6}>
                <Col className="p-0 float-right" md={10} lg={6}>
                  <Button
                    className="float-right"
                    variant="outline-light"
                    block
                    onClick={() => this.setState({ cancel: true })}
                  >
                    Annuleren
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
          <Container className="bg-light p-4 rounded-bottom" fluid>
            <p className="text-muted">
              <i>
                Het icoon <FontAwesomeIcon icon={IngenomenIcon} /> staat voor het aantal artikelen
                die al ingenomen zijn.
              </i>
            </p>
            <hr />
            <Col className="p-0">
              {this.state.hasFetched ? (
                this.state.uitgiften.length > 0 ? (
                  this.state.uitgiften.map(u => {
                    return <InnemenItem {...u} key={u.ID} handleModal={this.handleInnameModal} />;
                  })
                ) : (
                  <p className="my-3 text-center text-muted">
                    Er zijn geen items die ingenomen kunnen worden..
                  </p>
                )
              ) : (
                <div className="text-center my-3">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Col>
          </Container>
        </Container>
        <InnemenModal
          showModal={this.state.showModal}
          closeModal={this.closeModal}
          ID={this.state.selectedId}
          uitgiften={this.state.uitgiften}
          complete={() => this.fetchData()}
        />
      </Container>
    );
  }
}
